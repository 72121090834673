import { createSelector } from 'reselect';
import { ReducerFactory } from 'redux-actions-ts-reducer';
import { LOCATION_CHANGE } from 'connected-react-router';

import { GlobalState } from '../../../rootReducer';
import { LoadableData } from '../../../common/utils/LoadableData';
import { BackOfficeCompanyDTO, MailboxWorkflowDTO, MailboxDTO } from '../../../services/types/BoApiTypes';

import {
    boCompanyDetailsLoadableActions,
    addOrEditMailboxModalOpen,
    addOrEditMailboxModalClose,
    addMailboxLoadableActions,
    boCompanyMailboxesLoadableActions,
    updateMailboxLoadableActions,
    setEditingMailbox,
    clearEditingMailbox,
    mailboxDetailsLoadableActions,
    mailboxWorkflowLoadableActions,
    setEditingMailboxWorkflow,
    clearEditingMailboxWorkflow,
    saveMailboxWorkflowLoadableActions,
    boCompanyHistoryListLoadableActions,
    updateBoCompanyHistorySearchParams,
} from './BOCompanyDetailsViewActions';

import { NormalizedMailboxWorkflowToEdit } from './components/mailboxes/components/MailboxDetails/components/MailboxWorkflow/components/EditMailboxWorkflow/EditMailboxWorkflowTypes';
import { GetCompanyHistoryResponse, BOCompanyHistorySearchParams } from './components/boCompanyHistoryList/BOCompanyHistoryListTypes';

class State {
    boCompanyDetailsLoadable = new LoadableData<BackOfficeCompanyDTO, string>();
    addOrEditBoCompanyLoadable = new LoadableData<BackOfficeCompanyDTO, BackOfficeCompanyDTO>();
    mailboxDetailsLoadable = new LoadableData<MailboxDTO, MailboxDTO>();
    addOrEditMailboxLoadable = new LoadableData();
    addOrEditMailboxModalIsOpen = false;
    boCompanyMailboxes = new LoadableData<MailboxDTO[]>();
    updateMailboxLoadable = new LoadableData<MailboxDTO, string>();
    editingMailbox: MailboxDTO | null = null;
    mailboxWorkflowLoadable = new LoadableData<MailboxWorkflowDTO>();
    editingMailboxWorkflow: NormalizedMailboxWorkflowToEdit | null = null;
    editMailboxWorkflowLoadable = new LoadableData<MailboxWorkflowDTO>();
    boCompanyHistoryListLoadable = new LoadableData<GetCompanyHistoryResponse>();
    boCompanyHistoryListSearchParams: BOCompanyHistorySearchParams | null = null;
}

export default new ReducerFactory(new State())
    .addReducer(boCompanyDetailsLoadableActions.request, (state, action) => {
        return {
            ...state,
            boCompanyDetailsLoadable: LoadableData.loading(action.payload),
        };
    })
    .addReducer(boCompanyDetailsLoadableActions.success, (state, action) => {
        return {
            ...state,
            boCompanyDetailsLoadable: LoadableData.payload(action.payload),
        };
    })
    .addReducer(
        LOCATION_CHANGE,
        (): State => {
            return { ...new State() };
        },
    )
    .addReducer(addMailboxLoadableActions.request, (state) => {
        return { ...state, addOrEditMailboxLoadable: LoadableData.loading() };
    })
    .addReducer(addMailboxLoadableActions.success, (state) => {
        return { ...state, addOrEditMailboxLoadable: LoadableData.payload(null) };
    })
    .addReducer(addMailboxLoadableActions.error, (state, action) => {
        return { ...state, addOrEditMailboxLoadable: LoadableData.error(action.payload) };
    })
    .addReducer(addOrEditMailboxModalOpen, (state) => {
        return { ...state, addOrEditMailboxModalIsOpen: true };
    })
    .addReducer(addOrEditMailboxModalClose, (state) => {
        return { ...state, addOrEditMailboxModalIsOpen: false };
    })
    .addReducer(mailboxDetailsLoadableActions.request, (state, action) => {
        const payload = LoadableData.payload(action.payload);
        payload.loading = true;
        return { ...state, mailboxDetailsLoadable: payload };
    })
    .addReducer(mailboxDetailsLoadableActions.success, (state, action) => {
        return { ...state, mailboxDetailsLoadable: LoadableData.payload(action.payload) };
    })
    .addReducer(boCompanyMailboxesLoadableActions.success, (state, action) => {
        return { ...state, boCompanyMailboxes: LoadableData.payload(action.payload) };
    })
    .addReducer(updateMailboxLoadableActions.request, (state) => {
        return { ...state, updateMailboxLoadable: LoadableData.loading() };
    })
    .addReducer(updateMailboxLoadableActions.success, (state) => {
        return { ...state, updateMailboxLoadable: LoadableData.payload(null) };
    })
    .addReducer(updateMailboxLoadableActions.error, (state, action) => {
        return { ...state, updateMailboxLoadable: LoadableData.error(action.payload) };
    })
    .addReducer(setEditingMailbox, (state, action) => {
        return { ...state, editingMailbox: action.payload };
    })
    .addReducer(clearEditingMailbox, (state) => {
        return { ...state, editingMailbox: null };
    })
    .addReducer(mailboxWorkflowLoadableActions.request, (state) => {
        return { ...state, mailboxWorkflowLoadable: LoadableData.loading() };
    })
    .addReducer(mailboxWorkflowLoadableActions.success, (state, action) => {
        return { ...state, mailboxWorkflowLoadable: LoadableData.payload(action.payload) };
    })
    .addReducer(setEditingMailboxWorkflow, (state, action) => {
        return { ...state, editingMailboxWorkflow: action.payload };
    })
    .addReducer(clearEditingMailboxWorkflow, (state) => {
        return { ...state, editingMailboxWorkflow: null };
    })
    .addReducer(saveMailboxWorkflowLoadableActions.request, (state) => {
        return { ...state, editMailboxWorkflowLoadable: LoadableData.loading() };
    })
    .addReducer(saveMailboxWorkflowLoadableActions.success, (state) => {
        return { ...state, editMailboxWorkflowLoadable: LoadableData.payload(null) };
    })
    .addReducer(boCompanyHistoryListLoadableActions.success, (state, action) => {
        return { ...state, boCompanyHistoryListLoadable: LoadableData.payload(action.payload) };
    })
    .addReducer(updateBoCompanyHistorySearchParams, (state, action) => {
        return { ...state, boCompanyHistoryListSearchParams: action.payload };
    })
    .toReducer();

export { State as BOCompanyDetailsViewState };

export const selectBOCompanyDetailsLoadable = (state: GlobalState) => state.boCompanyDetails.boCompanyDetailsLoadable;
export const selectCompanyMailboxesLoadable = (state: GlobalState) => state.boCompanyDetails.boCompanyMailboxes;
export const selectEditingMailbox = (state: GlobalState) => state.boCompanyDetails.editingMailbox;
export const selectCompanyMailboxesCount = createSelector(selectCompanyMailboxesLoadable, (companyMailboxes) => companyMailboxes?.payload?.length || 0);

export const selectMailboxesState = createSelector(
    (state: GlobalState) => state.boCompanyDetails.addOrEditMailboxLoadable,
    (state: GlobalState) => state.boCompanyDetails.addOrEditMailboxModalIsOpen,
    selectCompanyMailboxesLoadable,
    (state: GlobalState) => state.boCompanyDetails.updateMailboxLoadable,
    (addOrEditMailboxesLoadable, addOrEditMailboxModalIsOpen, companyMailboxes, updateMailboxLoadable) => ({
        addOrEditMailboxesLoadable,
        addOrEditMailboxModalIsOpen,
        companyMailboxes: companyMailboxes.payload,
        isCompanyMailboxesFetching: Boolean(companyMailboxes.loading),
        isMailboxUpdating: Boolean(updateMailboxLoadable.loading),
    }),
);

export const selectMailboxDetailsLoadable = (state: GlobalState) => state.boCompanyDetails.mailboxDetailsLoadable;
export const selectEditingMailboxAndMailboxesCount = createSelector(selectEditingMailbox, selectCompanyMailboxesCount, (editingMailbox, mailboxesCount) => ({ editingMailbox, mailboxesCount }));

export const selectAddOrEditModalAndEditingMailbox = createSelector(
    selectEditingMailbox,
    (state: GlobalState) => state.boCompanyDetails.addOrEditMailboxModalIsOpen,
    (state: GlobalState) => state.boCompanyDetails.addOrEditMailboxLoadable.loading || state.boCompanyDetails.updateMailboxLoadable.loading,
    (editingMailbox, addOrEditMailboxModalIsOpen, isAddOrEditModalLoading) => ({ editingMailbox, addOrEditMailboxModalIsOpen, isAddOrEditModalLoading }),
);

export const selectMailboxDetailsGuid = createSelector(selectMailboxDetailsLoadable, (mailboxDetailsLoadable) => mailboxDetailsLoadable?.payload?.MailboxGuid);
export const selectMailboxWorkflowLoadable = (state: GlobalState) => state.boCompanyDetails.mailboxWorkflowLoadable;
export const selectEditingMailboxWorkflow = (state: GlobalState) => state.boCompanyDetails.editingMailboxWorkflow;
export const selectEditMailboxWorkflowLoadable = (state: GlobalState) => state.boCompanyDetails.editMailboxWorkflowLoadable;

export const selectMailboxWorkflowData = createSelector(selectMailboxWorkflowLoadable, selectMailboxDetailsGuid, (mailboxWorkflowLoadable, mailboxGuid) => ({
    mailboxWorkflowLoadable,
    mailboxGuid,
}));

export const selectEditMailboxWorkflow = createSelector(
    selectMailboxWorkflowLoadable,
    selectEditingMailboxWorkflow,
    selectEditMailboxWorkflowLoadable,
    selectMailboxDetailsLoadable,
    (mailboxWorkflowLoadable, editingMailboxWorkflow, editMailboxWorkflowLoadable, mailboxDetailsLoadable) => ({
        mailboxWorkflow: mailboxWorkflowLoadable.payload,
        editingMailboxWorkflow,
        isEditMailboxWorkflowLoading: Boolean(editMailboxWorkflowLoadable.loading),
        mailboxGuid: mailboxDetailsLoadable?.payload?.MailboxGuid,
    }),
);

export const selectBoCompanyHistoryListLoadable = (state: GlobalState) => state.boCompanyDetails.boCompanyHistoryListLoadable;
export const selectBoCompanyHistoryListSearchParams = (state: GlobalState) => state.boCompanyDetails.boCompanyHistoryListSearchParams;
