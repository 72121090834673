import { debounce } from 'lodash-es';
import * as React from 'react';
import { WithTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { Dispatchable1, Dispatchable2 } from 'redux-dispatchers';

import { isAuthorized, Role } from '../../common/user/userPermissionUtil';
import { createDataId } from '../../common/utils/dataId';
import { formatDate } from '../../common/utils/formatters';
import AccessLevel from '../../components/AccessLevel/AccessLevel';
import { BaseStatefulComponent } from '../../components/BaseStatefulComponent';
import { Button, ButtonIconPlacement, ButtonType } from '../../components/Buttons/Button';
import { ContentBlock } from '../../components/ContentBlock/ContentBlock';
import { ContentBlockBody } from '../../components/ContentBlock/ContentBlockBody';
import { ContentBlockFooter, ContentBlockFooterType } from '../../components/ContentBlock/ContentBlockFooter';
import { ContentBlockHeader, ContentBlockHeaderType } from '../../components/ContentBlock/ContentBlockHeader';
import { DropdownMenu, DropdownMenuItem } from '../../components/DropdownMenu/DropdownMenu';
import Icon, { ICONS, IconSize } from '../../components/Icon/Icon';
import { MainPage, MainPageType } from '../../components/MainPage/MainPage';
import ModalConfirm from '../../components/Modal/ModalConfirm';
import Pager from '../../components/Pager/Pager';
import { Switch } from '../../components/Switch/Switch';
import Table from '../../components/Table/Table';
import TableBody from '../../components/Table/TableBody';
import TableCell, { ResponsiveCellType } from '../../components/Table/TableCell';
import TableHead from '../../components/Table/TableHead';
import { DataTableHeader } from '../../components/Table/TableHeader';
import TableRow from '../../components/Table/TableRow';
import { Typography } from '../../components/Typography';
import { User } from '../../services/ApiClient';
import { AutoTransactionDTO, BaseSearch } from '../../services/types/ApiTypes';
import { ExportTypes, getReferenceNumberValue } from './AutoTransactionsViewHelper';
import { AutoTransactionsViewState } from './AutoTransactionsViewReducer';

import './AutoTransactionsView.scss';

export interface State {
    ruleIdToDelete: number | null;
}

export interface Props extends Pick<AutoTransactionsViewState, 'autoTransactionsLoadable' | 'deleteAutoTransactionLoadable' | 'searchParams'> {
    userData: User;
}

export interface DispatchProps {
    filterAutoTransactions: Dispatchable1<string>;
    setAutoTransactionsPagingOptions: Dispatchable2<number, number>;
    sortAutoTransactions: Dispatchable1<string>;
    searchAutoTransactions: Dispatchable1<BaseSearch>;
    deleteAutoTransaction: Dispatchable1<number>;
    openAutoTransaction: Dispatchable1<number>;
    duplicateAutoTransaction: Dispatchable1<number>;
    toggleRuleActivity: Dispatchable1<AutoTransactionDTO>;
    exportAutoTransactions: Dispatchable1<ExportTypes>;
}

interface RouteParams {}

export type AutoTransactionsViewProps = Props & DispatchProps & WithTranslation & RouteComponentProps<RouteParams>;

class AutoTransactionsView extends BaseStatefulComponent<AutoTransactionsViewProps, State> {
    state: State = {
        ruleIdToDelete: null,
    };

    componentDidMount() {
        if (!isAuthorized(Role.CanUseAutomation, this.props.userData)) {
            return this.props.history.push('/dashboard');
        }
        this.props.searchAutoTransactions(undefined);
    }

    debounceDoSearch = debounce(this.props.filterAutoTransactions, 400);

    setRuleIdToDelete = (ruleIdToDelete: number) => this.setState({ ruleIdToDelete });

    handleSearchInputClick = (value: string) => {
        this.debounceDoSearch(value);
    };

    handlePageChange = (page: number, pageSize?: number) => {
        this.props.setAutoTransactionsPagingOptions(page, pageSize);
    };

    getSearchValue() {
        const searchRestriction = this.props.searchParams.Restrictions.find((r) => r.Field === 'RuleName');
        return searchRestriction.Value;
    }

    render() {
        const { autoTransactionsLoadable, sortAutoTransactions, deleteAutoTransaction, exportAutoTransactions, t, history } = this.props;
        return (
            <MainPage className="auto-transactions" type={MainPageType.WIDE}>
                <ContentBlock>
                    <ContentBlockHeader
                        type={ContentBlockHeaderType.TABLE_HEADER}
                        hasSearch={true}
                        searchOptions={{ withSearchButton: true, dataId: createDataId('autoTransactionsSearch'), value: this.getSearchValue(), onClick: this.handleSearchInputClick }}
                        dataId={'autoTransaction.header'}
                        title={
                            <Typography variant="h1" element="span">
                                {t('component.AutoTransaction.Title')} ({(autoTransactionsLoadable.payload && autoTransactionsLoadable.payload.TotalCount) || 0})
                            </Typography>
                        }
                    >
                        <DropdownMenu
                            dataId={createDataId('autoTransaction', 'export')}
                            items={
                                <>
                                    <DropdownMenuItem
                                        icon={ICONS.CSV}
                                        onClick={() => {
                                            exportAutoTransactions(ExportTypes.CSV);
                                        }}
                                        dataId={createDataId('autoTransaction', 'exportToCsv')}
                                    >
                                        {t('views.global.csv')}
                                    </DropdownMenuItem>
                                    <DropdownMenuItem
                                        icon={ICONS.TABLE}
                                        onClick={() => {
                                            exportAutoTransactions(ExportTypes.XLSX);
                                        }}
                                        dataId={createDataId('autoTransaction', 'exportToExcel')}
                                    >
                                        {t('views.global.excel')}
                                    </DropdownMenuItem>
                                </>
                            }
                        >
                            <Button
                                className="content-block__header-text-button"
                                buttonType={ButtonType.ICON_TEXT}
                                icon={ICONS.EXPORT_24}
                                iconPlacement={ButtonIconPlacement.LEFT}
                                dataId={createDataId('autoTransaction', 'export')}
                            >
                                {t('views.global.export')}
                            </Button>
                        </DropdownMenu>
                        <AccessLevel role={Role.CanUseAutomation}>
                            <Button
                                className="content-block__header-text-button"
                                buttonType={ButtonType.ICON_TEXT}
                                onClick={() => {
                                    history.push('/auto-transactions/add');
                                }}
                                icon={ICONS.PLUS_THICK_24}
                                iconPlacement={ButtonIconPlacement.LEFT}
                                dataId={createDataId('autoTransaction', 'addNew')}
                            >
                                {t('views.global.Add')}
                            </Button>
                        </AccessLevel>
                    </ContentBlockHeader>
                    <ContentBlockBody className="auto-transactions__table" loading={autoTransactionsLoadable.loading} dataId={createDataId('autoTransaction', 'tableContent')}>
                        <div className="auto-transactions__table-content">
                            <Table>
                                <DataTableHeader data-id={createDataId('autoTransaction', 'tableHeader')}>
                                    <TableRow>
                                        <TableHead
                                            sortable={true}
                                            searchParams={autoTransactionsLoadable.request}
                                            columnName={'IsActive'}
                                            onClick={sortAutoTransactions}
                                            dataId={createDataId('autoTransactionsSort', 'isActive')}
                                            style={{ width: '5%' }}
                                        >
                                            {t('component.AutoTransaction.State')}
                                        </TableHead>
                                        <TableHead
                                            sortable={true}
                                            searchParams={autoTransactionsLoadable.request}
                                            columnName={'RuleName'}
                                            onClick={sortAutoTransactions}
                                            dataId={createDataId('autoTransactionsSort', 'ruleName')}
                                            style={{ width: '20%' }}
                                        >
                                            {t('component.AutoTransaction.RuleName')}
                                        </TableHead>
                                        <TableHead
                                            sortable={true}
                                            searchParams={autoTransactionsLoadable.request}
                                            columnName={'Supplier.Name'}
                                            onClick={sortAutoTransactions}
                                            dataId={createDataId('autoTransactionsSort', 'company')}
                                            style={{ width: '10%' }}
                                        >
                                            {t('component.AutoTransaction.Company')}
                                        </TableHead>
                                        <TableHead
                                            sortable={true}
                                            searchParams={autoTransactionsLoadable.request}
                                            columnName={'ReferenceNumber'}
                                            onClick={sortAutoTransactions}
                                            dataId={createDataId('autoTransactionsSort', 'referenceNumber')}
                                            style={{ width: '10%' }}
                                        >
                                            {t('component.AutoTransaction.ReferenceNumber')}
                                        </TableHead>
                                        <TableHead
                                            sortable={true}
                                            searchParams={autoTransactionsLoadable.request}
                                            columnName={'WorkflowTemplate.Name'}
                                            onClick={sortAutoTransactions}
                                            dataId={createDataId('autoTransactionsSort', 'confirmationFlow')}
                                            style={{ width: '10%' }}
                                        >
                                            {t('component.AutoTransaction.ConfirmationFlow')}
                                        </TableHead>
                                        <TableHead
                                            sortable={true}
                                            searchParams={autoTransactionsLoadable.request}
                                            columnName={'LastInvoice.Number'}
                                            onClick={sortAutoTransactions}
                                            dataId={createDataId('autoTransactionsSort', 'lastInvoice')}
                                            style={{ width: '10%' }}
                                        >
                                            {t('component.AutoTransaction.LastInvoice')}
                                        </TableHead>
                                        <TableHead
                                            sortable={true}
                                            searchParams={autoTransactionsLoadable.request}
                                            columnName={'LastRun'}
                                            onClick={sortAutoTransactions}
                                            dataId={createDataId('autoTransactionsSort', 'lastRun')}
                                            style={{ width: '10%' }}
                                        >
                                            {t('component.AutoTransaction.LastRun')}
                                        </TableHead>
                                        <TableHead className={'btn-wrapper'} style={{ width: '1%' }}>
                                            {'\u00A0'}
                                        </TableHead>
                                    </TableRow>
                                </DataTableHeader>
                                <TableBody dataId={createDataId('autoTransaction', 'tableBody')}>
                                    {autoTransactionsLoadable.payload &&
                                        autoTransactionsLoadable.payload.Items &&
                                        autoTransactionsLoadable.payload.Items.map((rule, id) => (
                                            <TableRow key={rule.Id} data-id={createDataId('autoTransactionsItem', 'row', `${rule.Id}`)}>
                                                <TableCell
                                                    data-id={createDataId('autoTransactionsItem', 'state', `${rule.Id}`)}
                                                    dataId={createDataId('autoTransactionsItem', id, 'isActive')}
                                                    nowrap={true}
                                                    responsiveCellType={ResponsiveCellType.LABEL}
                                                >
                                                    <Switch
                                                        value={rule.IsActive}
                                                        name={`at-switch-${id}`}
                                                        dataId={createDataId('autoTransactionsItem', id, 'switch')}
                                                        onChange={() => {
                                                            this.props.toggleRuleActivity(rule);
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell
                                                    data-id={createDataId('autoTransactionsItem', 'ruleName', `${rule.Id}`)}
                                                    link={true}
                                                    linkTo={`/auto-transactions/details/${rule.Id}`}
                                                    dataId={createDataId('autoTransactionsItem', id, 'ruleName')}
                                                    hideOverflow={true}
                                                    responsiveCellType={ResponsiveCellType.LABEL}
                                                >
                                                    {rule.RuleName}
                                                </TableCell>
                                                <TableCell
                                                    data-id={createDataId('autoTransactionsItem', 'company', `${rule.Id}`)}
                                                    link={true}
                                                    linkTo={`/auto-transactions/details/${rule.Id}`}
                                                    dataId={createDataId('autoTransactionsItem', id, 'supplier')}
                                                    hideOverflow={true}
                                                >
                                                    {rule.Supplier ? rule.Supplier.Name : ' '}
                                                </TableCell>
                                                <TableCell
                                                    data-id={createDataId('autoTransactionsItem', 'referenceNumber', `${rule.Id}`)}
                                                    link={true}
                                                    linkTo={`/auto-transactions/details/${rule.Id}`}
                                                    dataId={createDataId('autoTransactionsItem', id, 'referenceNumber')}
                                                    hideOverflow={true}
                                                >
                                                    {getReferenceNumberValue(rule, t)}
                                                </TableCell>
                                                <TableCell
                                                    data-id={createDataId('autoTransactionsItem', 'confirmationFlow', `${rule.Id}`)}
                                                    link={true}
                                                    linkTo={`/auto-transactions/details/${rule.Id}`}
                                                    dataId={createDataId('autoTransactionsItem', id, 'confirmationFlow')}
                                                    hideOverflow={true}
                                                >
                                                    {rule?.WorkflowTemplate?.Name || ''}
                                                </TableCell>
                                                <TableCell
                                                    data-id={createDataId('autoTransactionsItem', 'lastInvoice', `${rule.Id}`)}
                                                    link={true}
                                                    href={rule.LastInvoice ? `#/invoiceconfirmation/${rule.LastInvoice.Id}` : undefined}
                                                    linkTo={`/auto-transactions/details/${rule.Id}`}
                                                    dataId={createDataId('autoTransactionsItem', id, 'lastInvoice')}
                                                    hideOverflow={true}
                                                >
                                                    {rule.LastInvoice?.Number && (
                                                        <>
                                                            <span>{rule.LastInvoice.Number}</span>
                                                            <Icon className="auto-transactions__open-external ml-4" name={ICONS.OPEN_EXTERNAL} />
                                                        </>
                                                    )}
                                                </TableCell>
                                                <TableCell
                                                    data-id={createDataId('autoTransactionsItem', 'lastRun', `${rule.Id}`)}
                                                    link={true}
                                                    linkTo={`/auto-transactions/details/${rule.Id}`}
                                                    dataId={createDataId('autoTransactionsItem', id, 'lastRun')}
                                                    hideOverflow={true}
                                                >
                                                    {rule.LastRun ? formatDate(rule.LastRun, 'dd.MM.yyyy HH:mm') : ''}
                                                </TableCell>
                                                <TableCell
                                                    className="btn-wrapper"
                                                    dataId={createDataId('autoTransactionsItem', 'options', `${rule.Id}`)}
                                                    responsiveCellType={ResponsiveCellType.ACTIONS}
                                                >
                                                    <DropdownMenu
                                                        dataId={createDataId('autoTransactionsRowMenu', id)}
                                                        items={
                                                            <>
                                                                <DropdownMenuItem
                                                                    onClick={() => {
                                                                        this.props.duplicateAutoTransaction(rule.Id);
                                                                    }}
                                                                    icon={ICONS.DUPLICATE}
                                                                    dataId={createDataId('option', 'duplicate')}
                                                                >
                                                                    {t('component.AutoTransaction.Menu.Duplicate')}
                                                                </DropdownMenuItem>
                                                                <DropdownMenuItem
                                                                    onClick={() => {
                                                                        this.props.openAutoTransaction(rule.Id);
                                                                    }}
                                                                    icon={ICONS.EDIT}
                                                                    dataId={createDataId('option', 'edit')}
                                                                >
                                                                    {t('component.AutoTransaction.Menu.Edit')}
                                                                </DropdownMenuItem>
                                                                <DropdownMenuItem
                                                                    onClick={() => {
                                                                        this.setRuleIdToDelete(rule.Id);
                                                                    }}
                                                                    icon={ICONS.DELETE}
                                                                    dataId={createDataId('option', 'delete')}
                                                                >
                                                                    {t('views.global.Delete')}
                                                                </DropdownMenuItem>
                                                            </>
                                                        }
                                                    >
                                                        <Button dataId={createDataId('autoTransactionRowMenu', id, 'button')} icon={ICONS.MORE} iconSize={IconSize.SM} buttonType={ButtonType.ICON} />
                                                    </DropdownMenu>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>
                        </div>
                    </ContentBlockBody>
                    <ContentBlockFooter type={ContentBlockFooterType.PAGER}>
                        <Pager pages={autoTransactionsLoadable.payload} onPageChange={this.handlePageChange} />
                    </ContentBlockFooter>

                    <ModalConfirm
                        open={!!this.state.ruleIdToDelete}
                        onClose={() => {
                            this.setRuleIdToDelete(null);
                        }}
                        title={t('component.AutoTransaction.DeletePopover.Title')}
                        confirmText={`${t('views.global.Yes')}, ${t('component.AutoTransaction.Menu.Delete')}`}
                        handleActions={() => {
                            deleteAutoTransaction(this.state.ruleIdToDelete);
                            this.setRuleIdToDelete(null);
                        }}
                        dataId="deleteAT"
                    >
                        {t('component.AutoTransaction.DeletePopover.Message')}
                    </ModalConfirm>
                </ContentBlock>
            </MainPage>
        );
    }
}

export default AutoTransactionsView;
