import React, { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { isEmpty } from 'lodash-es';
import { Formik, FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import usePreventUnload from '../../common/hooks/usePreventUnload';
import { getCurrencyName } from '../../common/user/userUtils';
import { createDataId } from '../../common/utils/dataId';
import { paths } from '../../common/router/routePaths';
import { createAnchorTo } from '../../common/utils/createAnchorTo';
import { WithMenuLockConfirmation } from '../../common/menu-lock/WithMenuLockConfirmation';
import { setPromptDialogHide, setConfirmLocation, menuLockStateSelector, setMenuLocked } from '../../common/menu-lock/MenuLockReducer';
import { PurchaseOrderType } from '../../services/types/ApiTypes';
import { ContentBlock } from '../../components/ContentBlock/ContentBlock';
import { ContentBlockBody } from '../../components/ContentBlock/ContentBlockBody';
import { ContentBlockHeader } from '../../components/ContentBlock/ContentBlockHeader';
import { ContentBlockFooter, ContentBlockFooterType } from '../../components/ContentBlock/ContentBlockFooter';
import { Button, ButtonType } from '../../components/Buttons/Button';
import { MainPage, MainPageContent, MainPageType } from '../../components/MainPage/MainPage';
import { WithTranslateFormErrors } from '../../components/WithTranslateFormErrors/WithTranslateFormErrors';
import Icon, { ICONS } from '../../components/Icon/Icon';
import { defaultCurrency } from '../purchase-orders-add/PurchaseOrdersAddViewHelper';
import fieldNames from '../purchase-orders-add/purchaseOrderAddViewFields';
import { createPurchaseOrdersDTO, dataId, dataIdAttachments, dropZoneId, fieldNamesSource, getInitialValue, normalizePORowsToSubmit } from './PurchaseOrdersNewHelper';
import { Props, PurchaseOrderNewRowFields, PurchaseOrdersNewViewFields } from './PurchaseOrdersNewTypes';
import Header from './components/Header/Header';
import Rows from './components/Rows/Rows';
import Attachments from './components/Attachments/Attachments';
import { FileType } from './components/Attachments/components/FileInput/FileInputTypes';
import { ConditionalConfirmationTooltip } from './components/ConditionalConfirmationTooltip';

import './PurchaseOrdersNew.scss';

const PurchaseOrdersNew: React.FC<Props> = ({
    currentCompanySettingsLoading,
    currentCompanySettings,
    addSupplierLoadable,
    getCurrentCompanySettings,
    addNewPurchaseOrder,
    showAddOrEditSupplierModal,
    history,
}) => {
    const { locationOnConfirm, showPrompt, locked } = useSelector(menuLockStateSelector);
    const [purchaseOrderFormValues, setPurchaseOrderFormValues] = useState<PurchaseOrdersNewViewFields>({} as PurchaseOrdersNewViewFields);
    const [purchaseOrderRowValues, setPurchaseOrderRowValues] = useState<Array<PurchaseOrderNewRowFields>>(getInitialValue);
    const [purchaseOrderFileValues, setPurchaseOrderFileValues] = useState<Array<FileType>>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { t } = useTranslation();
    const formRef = useRef<Formik<PurchaseOrdersNewViewFields>>();
    const dispatch = useDispatch();

    const validationSchema = Yup.object<PurchaseOrdersNewViewFields>().shape({
        [fieldNames.Supplier]: Yup.object()
            .nullable(true)
            .required(t('view.Accounting.MandatoryField', { fieldName: t('view.PurchaseOrders.Supplier') })),
        [fieldNames.Description]: Yup.string()
            .ensure()
            .required(t('view.Accounting.MandatoryField', { fieldName: t('view.Accounting.Description') })),
    });

    const handleClickBack = () => {
        history.push(paths.app.listPurchaseOrders);
    };

    const handleClickConfirmAgree = () => {
        dispatch(setPromptDialogHide());
        if (locationOnConfirm) {
            return createAnchorTo(locationOnConfirm);
        }
        handleClickBack();
    };

    const handleClickConfirmCancel = () => {
        dispatch(setConfirmLocation(null));
        dispatch(setPromptDialogHide());
    };

    const handleClickCancel = () => {
        !locked && handleClickBack();
    };

    const handleFormSubmit = () => {
        formRef?.current.submitForm();
    };

    const handleSubmit = async (values: PurchaseOrdersNewViewFields) => {
        setIsLoading(true);
        const newPo = { ...values };
        newPo.Rows = normalizePORowsToSubmit(purchaseOrderRowValues);
        newPo.DocumnetFiles = purchaseOrderFileValues;
        const purchaseOrder = await createPurchaseOrdersDTO(newPo);
        await addNewPurchaseOrder(purchaseOrder);
        setIsLoading(false);
    };

    const handleChangedRows = () => {
        dispatch(setMenuLocked());
    };

    usePreventUnload(true);

    useEffect(() => {
        // FIXME COMPANY_SETTINGS_INITIAL_LOADING
        if (isEmpty(currentCompanySettings)) {
            getCurrentCompanySettings();
        }
    }, []);

    useEffect(() => {
        if (!currentCompanySettingsLoading && !isEmpty(currentCompanySettings) && isEmpty(purchaseOrderFormValues)) {
            const newPurchaseOrderValues = { ...fieldNamesSource };
            const companyCurrency = currentCompanySettings.find((s) => s.Name === 'DefaultCurrency')?.Value || defaultCurrency;
            newPurchaseOrderValues.Currency = {
                text: `${companyCurrency} - ${getCurrencyName(companyCurrency)}`,
                value: companyCurrency,
            };
            newPurchaseOrderValues.Type = PurchaseOrderType.Opex;
            setPurchaseOrderFormValues(newPurchaseOrderValues);
        }
    }, [currentCompanySettingsLoading, currentCompanySettings]);

    return (
        <MainPage id={dropZoneId} className={'purchase-order-new'} type={MainPageType.HAS_SIDEBAR}>
            <MainPageContent>
                <ContentBlock loading={false}>
                    <ContentBlockHeader onBackClick={handleClickBack} className="purchase-order-new__header">
                        <ConditionalConfirmationTooltip
                            dataId="po-details-new.back-button.confirmation"
                            onConfirm={handleClickConfirmAgree}
                            onCancel={handleClickConfirmCancel}
                            title={t('view.PurchaseOrders.ConfirmationModal.Title')}
                            confirmText={t('view.PurchaseOrders.ConfirmationModal.ConfirmText')}
                            cancelText={t('view.PurchaseOrders.ConfirmationModal.CancelText')}
                            type="alert"
                            className="purchase-order-new__confirmation-tooltip"
                            buttonsSize="sm"
                            shouldShowConfirmation={locked}
                            placement="bottom-start"
                        >
                            <button type="button" className="content-block__back purchase-order-new__header__back-button" data-id="contentBlockBackButton" onClick={handleClickCancel}>
                                <Icon name={ICONS.ARROW_LEFT_24} />
                            </button>
                        </ConditionalConfirmationTooltip>
                        <h2 data-id={createDataId(dataId, 'title')}>{t('view.PurchaseOrders.New.Title')}</h2>
                    </ContentBlockHeader>
                </ContentBlock>
                <ContentBlock loading={false}>
                    <ContentBlockBody dataId={dataId} className={'purchase-order-new__form'}>
                        <Formik ref={formRef} onSubmit={handleSubmit} initialValues={purchaseOrderFormValues} validationSchema={validationSchema} enableReinitialize validateOnChange validateOnBlur>
                            {(formik: FormikProps<PurchaseOrdersNewViewFields>) => (
                                <WithMenuLockConfirmation formik={formik}>
                                    <WithTranslateFormErrors errors={formik.errors} setFieldTouched={formik.setFieldTouched} touched={formik.touched}>
                                        <Header
                                            setPurchaseOrderFormValues={setPurchaseOrderFormValues}
                                            showAddOrEditSupplierModal={showAddOrEditSupplierModal}
                                            addSupplierLoadable={addSupplierLoadable}
                                            formik={formik}
                                        />
                                    </WithTranslateFormErrors>
                                </WithMenuLockConfirmation>
                            )}
                        </Formik>
                        <Rows rows={purchaseOrderRowValues} setPurchaseOrderRowValues={setPurchaseOrderRowValues} onChanged={handleChangedRows} />
                        <Attachments dataId={dataIdAttachments} dropZoneId={dropZoneId} files={purchaseOrderFileValues} setPurchaseOrderFileValues={setPurchaseOrderFileValues} />
                        <ContentBlockFooter contentClass="purchase-order-new__footer" type={ContentBlockFooterType.PAGER}>
                            <div className="purchase-order-new__footer-buttons">
                                <ConditionalConfirmationTooltip
                                    dataId="po-details-new.cancel-button.confirmation"
                                    onConfirm={handleClickConfirmAgree}
                                    open={showPrompt}
                                    onCancel={handleClickConfirmCancel}
                                    title={t('view.PurchaseOrders.ConfirmationModal.Title')}
                                    confirmText={t('view.PurchaseOrders.ConfirmationModal.ConfirmText')}
                                    cancelText={t('view.PurchaseOrders.ConfirmationModal.CancelText')}
                                    type="alert"
                                    placement="top-start"
                                    className="purchase-order-new__confirmation-tooltip"
                                    buttonsSize="sm"
                                    shouldShowConfirmation={locked}
                                >
                                    <Button type="button" buttonType={ButtonType.HEADER_SECONDARY} dataId={createDataId(dataId, 'footer', 'cancel')} onClick={handleClickCancel}>
                                        {t('views.global.Cancel')}
                                    </Button>
                                </ConditionalConfirmationTooltip>

                                <Button loading={isLoading} onClick={handleFormSubmit} buttonType={ButtonType.HEADER_PRIMARY} dataId={createDataId(dataId, 'footer', 'save')}>
                                    {t('views.purchaseOrders.newPO.button.create')}
                                </Button>
                            </div>
                        </ContentBlockFooter>
                    </ContentBlockBody>
                </ContentBlock>
            </MainPageContent>
        </MainPage>
    );
};

export default PurchaseOrdersNew;
