import { createAction } from 'redux-actions';

import { Response, PasswordDTO, BooleanResponse, SessionDTO } from '../../services/types/ApiTypes';
import { BackOfficeAssistantDTO, BackOfficeUserDTO } from '../../services/types/BoApiTypes';
import { getBoCurrentUserLoadableAction, getBoCurrentUser } from '../../views/back-office/common/BoUserActions';
import { notify } from '../../common/utils/notify';
import { loadableDataActions } from '../../common/utils/LoadableData';
import i18n from '../../i18n';
import api from '../../services/ApiServices';
import { DispatchThunk } from '../../storeConfig';
import { getWhitelabelParameter } from '../../common/utils/whitelabelHelper';
import { getCurrentUser } from '../../common/user/UserActions';
import { sendEmailVerificationLink } from '../EmailVerification/EmailVerificationHelper';
import { setEmailVerificationPendingSetting } from '../EmailVerification/EmailVerificationActions';

const ns = 'user-profile/';

export const showUserProfile = createAction<boolean | string>(`${ns}SHOW_USER_PROFILE`);
export const showChangePassword = createAction<boolean>(`${ns}SHOW_CHANGE_PASSWORD`);
export const changePasswordActions = loadableDataActions<undefined, Response<any>>(`${ns}CHANGE_PASSWORD`);
export const getAssistantsLoadableActions = loadableDataActions<null, BackOfficeAssistantDTO[]>(`${ns}GET_USER_ASSISTANTS`);
export const getSessionsLoadableActions = loadableDataActions<null, SessionDTO[]>(`${ns}GET_USER_SESSIONS`);
export const saveAssistantsLoadableActions = loadableDataActions<null, BackOfficeAssistantDTO[]>(`${ns}SAVE_USER_ASSISTANTS`);
export const sendVerificationCodeToEmailActions = loadableDataActions<undefined, BooleanResponse>(`${ns}SEND_VERIFICATION_CODE_TO_EMAIL`);
export const verifyEmailActions = loadableDataActions<undefined, BooleanResponse>(`${ns}VERIFY_EMAIL_ACTION`);

export function changePassword(passwords: PasswordDTO) {
    return async (dispatch: DispatchThunk) => {
        try {
            dispatch(changePasswordActions.request(undefined));
            const response = await api.user.changeUserPassword(passwords);
            if (response.data.Success) {
                dispatch(changePasswordActions.success(response.data));
                notify.success(i18n.t('component.changePassword.passwordChangedSuccessfully'), i18n.t('component.changePassword.success'));
            } else {
                dispatch(changePasswordActions.error(new Error(response.data.Message)));
                notify.error(i18n.t(response.data.Message), i18n.t('component.changePassword.error'));
            }
        } catch (e) {
            console.error(e);
            notify.error(i18n.t('component.changePassword.error'));
            dispatch(changePasswordActions.error(e));
        }
    };
}

export async function resetUserPassword(userGuid: string, password: string, name?: string) {
    try {
        const response = await api.boUsers.resetUserPassword(userGuid, password, getWhitelabelParameter());
        if (response.data.Success) {
            notify.success(
                i18n.t('view.backOffice.allUsers.resetPasswordForUser.Successfully', {
                    userName: name,
                }),
                i18n.t('component.changePassword.success'),
            );
        } else {
            notify.error(
                i18n.t('view.backOffice.allUsers.resetPasswordForUser.ErrorText', {
                    userName: name,
                }),
                i18n.t('component.changePassword.error'),
            );
        }
    } catch (e) {
        console.error(e);
        notify.error(
            i18n.t('view.backOffice.allUsers.resetPasswordForUser.ErrorText', {
                userName: name,
            }),
            i18n.t('component.changePassword.error'),
        );
    }
}

export function updateUserDetails(user: BackOfficeUserDTO, sendVerificationLink?: boolean) {
    return async (dispatch: DispatchThunk) => {
        try {
            dispatch(getBoCurrentUserLoadableAction.request(null));
            const response = await api.user.saveUser(user);
            dispatch(getBoCurrentUserLoadableAction.success(response.data));
            dispatch(getCurrentUser());
            if (sendVerificationLink) {
                sendEmailVerificationLink(user.UserGuid, user.Email);
                dispatch(setEmailVerificationPendingSetting(true));
            }
            notify.success(i18n.t('component.userProfileDetails.success'));
        } catch (e) {
            console.error(e);
            notify.error(i18n.t('component.userProfileDetails.error'));
        }
    };
}

export function getAssistantsForUser(userGuid: string) {
    return async (dispatch: DispatchThunk) => {
        dispatch(getAssistantsLoadableActions.request(null));

        try {
            const response = await api.boAssistants.getAssistantsForUser(userGuid);
            if (response.data) {
                dispatch(getAssistantsLoadableActions.success(response.data));
            } else {
                notify.error(i18n.t('component.userProfileAssistants.error'));
            }
        } catch (e) {
            dispatch(getAssistantsLoadableActions.error(e));
            notify.error(i18n.t('component.userProfileAssistants.error'));
            console.error(e);
        }
    };
}

export function saveAssistantsForUser(userGuid: string, assistants: BackOfficeAssistantDTO[]) {
    return async (dispatch: DispatchThunk) => {
        dispatch(saveAssistantsLoadableActions.request(null));

        try {
            const response = await api.boAssistants.saveAssistantsForUser(userGuid, assistants);
            notify.success(i18n.t('component.userProfileAssistants.success'));
            dispatch(saveAssistantsLoadableActions.success(response.data));
        } catch (e) {
            dispatch(saveAssistantsLoadableActions.error(e));
            notify.error(i18n.t('component.userProfileAssistants.error'));
            console.error(e);
        }
    };
}

export function sendVerificationCodeToEmail(email: string) {
    return async (dispatch: DispatchThunk) => {
        try {
            dispatch(sendVerificationCodeToEmailActions.request(undefined));
            const response = await api.boUsers.sendVerificationCodeToEmail(email);
            if (response.data.Success) {
                dispatch(sendVerificationCodeToEmailActions.success(response.data));
            } else {
                dispatch(sendVerificationCodeToEmailActions.error(new Error(response.data.Message)));
                notify.error(response.data.Message);
            }
        } catch (e) {
            console.error(e);
            notify.error(i18n.t('component.emailVerification.sendCode.error'));
            dispatch(sendVerificationCodeToEmailActions.error(e));
        }
    };
}

export function verifyEmail(code: string) {
    return async (dispatch: DispatchThunk) => {
        try {
            dispatch(verifyEmailActions.request(undefined));
            const response = await api.boUsers.verifyEmail(code);
            if (response.data.Success) {
                dispatch(verifyEmailActions.success(response.data));
                notify.success(i18n.t('component.emailVerification.verify.success'));
                await dispatch(getBoCurrentUser());
            } else {
                dispatch(verifyEmailActions.error(new Error(response.data.Message)));
                notify.error(response.data.Message);
            }
        } catch (e) {
            console.error(e);
            notify.error(i18n.t('component.emailVerification.verify.error'));
            dispatch(verifyEmailActions.error(e));
        }
    };
}

export function getUserSessions() {
    return async (dispatch: DispatchThunk) => {
        dispatch(getSessionsLoadableActions.request(null));

        try {
            const response = await api.sessions.getMySessions();
            if (response.data) {
                dispatch(getSessionsLoadableActions.success(response.data));
            } else {
                notify.error(i18n.t('component.userProfileGetSessions.error'));
            }
        } catch (e) {
            dispatch(getSessionsLoadableActions.error(e));
            notify.error(i18n.t('component.userProfileGetSessions.error'));
            console.error(e);
        }
    };
}

export function terminateSessions(sessionsIds: number[]) {
    return async (dispatch: DispatchThunk) => {
        try {
            const response = await api.sessions.terminateSessions(sessionsIds);
            if (response.data) {
                dispatch(getUserSessions);
                notify.success(i18n.t('component.userProfileTerminateSessions.success'));
            } else {
                notify.error(i18n.t('component.userProfileTerminateSessions.error'));
            }
        } catch (e) {
            console.error(e);
            notify.error(i18n.t('component.userProfileTerminateSessions.error'));
        }
    };
}
