import { TFunction } from 'i18next';
import api from '../../../services/ApiServices';
import { TagSelectItem } from '../../../components/TagSelect/TagSelect';
import { BaseSearch, InvoiceType, InvoiceTypeDTO, SearchType, SortDirection } from '../../../services/types/ApiTypes';
import { InvoiceTypesSearchParams } from './InvoiceTypesListReducer';

export const DEFAULT_RESTRICTION = 'GeneralSearch';

export const createRequest = (searchValue = '', sortColumn = 'Code', page = 1, count = 15): InvoiceTypesSearchParams => ({
    PagingOptions: {
        Count: count,
        Page: page,
    },
    Restrictions:
        searchValue && searchValue.length > 0
            ? [
                  {
                      Field: '',
                      FieldSearchType: SearchType.NotSelected,
                      Value: searchValue || null,
                      Values: null,
                  },
              ]
            : [],
    SortItems: [
        {
            SortColumn: sortColumn,
            SortDirection: SortDirection.Asc,
        },
    ],
    filters: {},
});

export const INVOICE_TYPES = {
    [InvoiceType.PurchaseInvoice]: 'report.InvoiceFrontPage.InvoiceType.PurchaseInvoice',
    [InvoiceType.Expense]: 'views.invoice.partials.invoiceInformation.expense',
};

export const getInvoiceTypeLabel = (type: InvoiceType) => {
    return INVOICE_TYPES[type];
};

export const getInvoiceTypesList = (t: TFunction): Array<TagSelectItem<any>> => {
    return Object.keys(INVOICE_TYPES).map((key) => ({
        text: t(INVOICE_TYPES[key]),
        value: key,
    }));
};

export const getInvoiceTypesTypeAheadList = async (searchString: string): Promise<Array<InvoiceTypeDTO>> => {
    const searchParams: BaseSearch = {
        SortItems: [
            {
                SortColumn: 'Code',
                SortDirection: SortDirection.Asc,
            },
        ],
        PagingOptions: {
            Page: 1,
            Count: 25,
        },
        Restrictions: [
            {
                Field: DEFAULT_RESTRICTION,
                Value: searchString || '',
                Values: null,
                FieldSearchType: SearchType.NotSelected,
            },
        ],
    };
    const invoiceTypesResponse = await api.invoiceTypes.getInvoiceTypes(searchParams, false);
    return Promise.resolve(invoiceTypesResponse.data.Items);
};

export const getInvoiceTypesTypeaheadItems = async (searchString: string): Promise<Array<InvoiceTypeDTO>> => {
    const managers = await getInvoiceTypesTypeAheadList(searchString);
    return managers;
};

export const getDefaulInvoiceType = async (invoiceType: InvoiceType) => {
    try {
        const r = await api.invoiceTypes.getInvoiceTypes(
            {
                Restrictions: [],
                SortItems: [
                    {
                        SortColumn: 'IsDefault',
                        SortDirection: SortDirection.Desc,
                    },
                ],
                PagingOptions: {
                    Page: 1,
                    Count: 2,
                },
            },
            false,
        );
        return r.data.Items.find((i) => i.Type === invoiceType && i.IsDefault);
    } catch (error) {
        console.error(error);
        return null;
    }
};
