import classNames from 'classnames';
import * as React from 'react';

import { BaseComponent } from '../BaseComponent';

import './MainPage.scss';

export interface MainPageProps {
    className?: string;
    type?: MainPageType;
    id?: string;
}
export enum MainPageType {
    HAS_SIDEBAR = 'HAS_SIDEBAR',
    WIDE = 'WIDE',
}

export class MainPage extends BaseComponent<MainPageProps> {
    render() {
        const classes = classNames(
            'main-page',
            'pb-6',
            this.props.className,
            { 'main-page--has-sidebar': this.props.type === MainPageType.HAS_SIDEBAR },
            { 'main-page--wide': this.props.type === MainPageType.WIDE },
        );
        return (
            <main className={classes} data-id="mainPage.main" id={this.props.id}>
                <>{this.props.children}</>
            </main>
        );
    }
}

export interface MainPageContentProps {
    children?: React.ReactNode;
}

export const MainPageContent: React.FC<MainPageContentProps> = ({ children }: MainPageContentProps) => {
    return (
        <section className="main-page__content" data-id="mainPage.content">
            {children}
        </section>
    );
};

export interface MainPageAsideProps {
    children?: React.ReactNode;
}

export const MainPageAside: React.FC<MainPageAsideProps> = ({ children }: MainPageAsideProps) => {
    return (
        <aside className="main-page__aside" data-id="mainPage.aside">
            {children}
        </aside>
    );
};
