import { createTKey } from '../../../../../../common/utils/createTKey';

const supportedExtensinList = [
    'zip',
    'rar',
    'gzip',
    '7zip',
    'tar',
    'bz2',
    'gz',
    'lz',
    'lz4',
    'gz',
    'tgz',
    '7z',
    'zipx',
    'pdf',
    'doc',
    'docx',
    'odt',
    'fodt',
    'rtf',
    'pages',
    'xls',
    'xlsx',
    'xlsb',
    'csv',
    'ods',
    'fods',
    'odf',
    'xlr',
    'pps',
    'ppt',
    'pptx',
    'xml',
    'jpg',
    'jpeg',
    'jfif',
    'png',
    'bmp',
    'heic',
    'odg',
    'fodg',
    'gif',
    'tga',
    'tif',
    'tiff',
    'webp',
    'txt',
    'log',
    'tex',
    'dat',
    'msg',
    'eml',
    'adoc',
    'bdoc',
    'cdoc',
    'ddoc',
    'edoc',
    'asice',
    'sce',
];

const getExtenstion = (name: string): string => name.split('.').pop();

export const isSupportedExtension = (name: string): boolean => {
    const extension = getExtenstion(name);
    return supportedExtensinList.includes(extension);
};

export const convertBytesToMb = (bytes: number): string => (bytes / 1048576).toFixed(0);

const rootTKey = 'component.attachments';

export const translations = {
    dropZoneTitle: createTKey(rootTKey, 'dropzone', 'title'),
    dropZoneSubtitle: createTKey(rootTKey, 'dropzone', 'subtitle'),
    buttonUpload: createTKey(rootTKey, 'button', 'upload'),
    buttonDragFile: createTKey(rootTKey, 'button', 'orDragFile'),
    notificationUploadingTitle: createTKey(rootTKey, 'notification', 'uploading', 'title'),
    notificationUploadingText: createTKey(rootTKey, 'notification', 'uploading', 'text'),
    notificationSuccessTitle: createTKey(rootTKey, 'notification', 'success', 'title'),
    notificationSuccessText: createTKey(rootTKey, 'notification', 'success', 'text'),
    notificationErrorTitle: createTKey(rootTKey, 'notification', 'error', 'title'),
    notificationErrorText: createTKey(rootTKey, 'notification', 'error', 'text'),
    notificationErrorUnsupportedTypeTitle: createTKey(rootTKey, 'notification', 'error', 'unsupportedType', 'title'),
    notificationErrorUnsupportedTypeText: createTKey(rootTKey, 'notification', 'error', 'unsupportedType', 'text'),
    notificationErrorTotalSizeTitle: createTKey(rootTKey, 'notification', 'error', 'totalSize', 'title'),
    notificationErrorTotalSizeText: createTKey(rootTKey, 'notification', 'error', 'totalSize', 'text'),
    notificationErrorMaxAmountText: createTKey(rootTKey, 'notification', 'error', 'maxAmount', 'text'),
};
