import { FormikProps } from 'formik';
import { TFunction } from 'i18next';
import { isEqual, size, values as _values } from 'lodash-es';
import * as React from 'react';
import FormikField from '../../../../common/utils/FormikField';

import { createDataId } from '../../../../common/utils/dataId';
import { flattenObject } from '../../../../common/utils/flattenObject';
import { notify } from '../../../../common/utils/notify';
import { BaseComponent } from '../../../../components/BaseComponent';
import { Button, ButtonIconPlacement, ButtonType } from '../../../../components/Buttons/Button';
import { ContentBlockHeader } from '../../../../components/ContentBlock/ContentBlockHeader';
import { ICONS } from '../../../../components/Icon/Icon';
import { TextInputField, TextInputType } from '../../../../components/TextInput/TextInput';
import fieldNames, { AutoTransactionsAddViewFields } from '../../autoTransactionAddViewFields';

import './Header.scss';

interface HeaderProps {
    t: TFunction;
    isNew: boolean;
    isReadOnly: boolean;
    ruleName: string;
    ruleDescription: string;
    errors: {};
    isFormValid: boolean;
    formik: FormikProps<AutoTransactionsAddViewFields>;
    onSubmit: () => void;
}

class Header extends BaseComponent<HeaderProps> {
    handleBackClick = () => {
        const { t } = this.props;
        const { initialValues, values } = this.props.formik;
        const formHasChanges = !isEqual(initialValues, values);
        if (formHasChanges) {
            const continueBackNavigation = confirm(t('component.AutoTransaction.ConfirmLeavingWithUnsavedChanges'));
            if (!continueBackNavigation) {
                return;
            }
        }
        history.back();
    };

    componentDidUpdate(prevProps: Readonly<HeaderProps>) {
        const { t, errors, formik } = this.props;
        if (prevProps.formik.isSubmitting !== formik.isSubmitting && !formik.isSubmitting) {
            const isValid = size(errors) === 0;

            if (!isValid) {
                const errorTexts = _values(flattenObject(errors)).filter(Boolean);
                notify.warning(
                    <ul>
                        {errorTexts.map((e) => (
                            <li key={e}>&bull; {e}</li>
                        ))}
                    </ul>,
                    t('component.AutoTransaction.Error.GeneralValidationError'),
                    {
                        toastOptions: {
                            autoClose: 15000,
                        },
                    },
                );
            }
        }
    }

    render() {
        const { t, isNew, isReadOnly, ruleName, ruleDescription, formik } = this.props;
        return (
            <ContentBlockHeader onBackClick={this.handleBackClick} hasBackButton={true}>
                <div className="description-wrap">
                    <FormikField
                        component={TextInputField}
                        textarea={true}
                        name={fieldNames.ruleName}
                        placeholder={t('component.AutoTransaction.RuleName.Add')}
                        onlyChangeOnBlur={true}
                        type={TextInputType.LARGE}
                        onFocusPlaceholder={t('component.AutoTransaction.PlaceHolder.TypeToSet')}
                        dataId={fieldNames.ruleName}
                        disabled={isReadOnly}
                        value={ruleName}
                        maxLength={128}
                        autofocus={isNew && !ruleName}
                        blurOnEnter={true}
                        restoreOnEsc={true}
                    />
                    <FormikField
                        component={TextInputField}
                        textarea={true}
                        name={fieldNames.ruleDescription}
                        placeholder={t('component.AutoTransaction.Comment.Add')}
                        onlyChangeOnBlur={true}
                        onFocusPlaceholder={t('component.AutoTransaction.PlaceHolder.TypeToSet')}
                        dataId={fieldNames.ruleDescription}
                        disabled={isReadOnly}
                        maxLength={256}
                        value={ruleDescription}
                        blurOnEnter={true}
                        restoreOnEsc={true}
                    />
                </div>
                <div className="action-wrap action-wrap--top">
                    <Button
                        disabled={!formik.dirty}
                        buttonType={ButtonType.ICON_TEXT}
                        dataId={createDataId('automationRule.save', 'button')}
                        icon={ICONS.SAVE_24}
                        onClick={() => formik.submitForm()}
                        iconPlacement={ButtonIconPlacement.LEFT}
                    >
                        {t('views.global.save')}
                    </Button>
                    <Button
                        className="discard"
                        onClick={() => formik.handleReset()}
                        dataId={createDataId('automationRule.discard', 'button')}
                        buttonType={ButtonType.ICON_TEXT}
                        icon={ICONS.CANCEL_CHANGES_24}
                        iconPlacement={ButtonIconPlacement.LEFT}
                    >
                        {t('component.basicInfo.discardChanges')}
                    </Button>
                </div>
            </ContentBlockHeader>
        );
    }
}

export default Header;
