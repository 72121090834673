import { selectCurrentCompanySettings } from '../company/CompanySelectors';
import { useSelector } from 'react-redux';
import { SystemConfigurationDTO } from '../../services/types/ApiTypes';
import { useCallback } from 'react';

export type CompanySettingName =
    | 'AllowDimensionApisToCreateCco'
    | 'AllowLoginWithEmailAndPassword'
    | 'AllowLoginWithUsernameAndPassword'
    | 'AllowProcessingZeroInvoices'
    | 'AutoAssignToContactPerson'
    | 'CombinedRowDescription'
    | 'CompanyRoundingOption'
    | 'ConfirmationFlowUserIdentifier'
    | 'CurrentPurchaseOrderNumber'
    | 'CustomLoginUrl'
    | 'DefaultCurrency'
    | 'EstonianRegNoTransformationEnabled'
    | 'ExportCompanySupplierDataInsteadOfOriginal'
    | 'ExportCSVDelimiterSymbol'
    | 'ExportCSVIncludeColumnsSeparatorInformation'
    | 'IncludeInvoiceRowsIntoExport'
    | 'IncludeLinkedPurchaseOrdersInformationIntoExport'
    | 'IncludePdfIntoExport'
    | 'InvoicesExportMode'
    | 'IsAiAccountingModuleActive'
    | 'IsArchiveModulActive'
    | 'IsBypassFitekinModuleActive'
    | 'IsConfirmAllAllowed'
    | 'IsDigitizationModulActive'
    | 'IsERPIdEnabled'
    | 'IsExpensesModuleActive'
    | 'IsExportWithUnregisteredSupplierDenied'
    | 'isInvoiceRegisterModulActive'
    | 'IsKIEstonianSearchDisabled'
    | 'IsMobileOCRModulActive'
    | 'IsProductItemsModulActive'
    | 'IsPurchaseOrdersModulActive'
    | 'IsQuickConfirmAllowed'
    | 'IsTransactionRowsCheckEnabled'
    | 'IsTransactionRowsPenultimateCheckEnabled'
    | 'IsVatCodeManualEditingEnabled'
    | 'IsVatSumDeviationWarningEnabled'
    | 'MonetaryAmountsItemPricePrecision'
    | 'MonetaryAmountsQuantityPrecision'
    | 'MonetaryNetVatTotalPrecision'
    | 'NewSupplierEmail'
    | 'NewVendorNotificationLanguage'
    | 'NotifyExternalApiInvoice'
    | 'NotifyExternalApiPurchaseOrder'
    | 'OverwriteWithRelatedDimension'
    | 'PurchaseOrderPrefix'
    | 'PurchaseOrderSuffix'
    | 'PushInvoiceAfterExportUrl'
    | 'SerbiaMidLayerEndpoint'
    | 'ShowInvoicePdfPreviewAutomatically'
    | 'SkipKeywordForSupplierMatchingByBankAccount'
    | 'SkipTransactionRowsVerificationWhenPOSpecified'
    | 'SupportEmail'
    | 'SupportPhone'
    | 'SupportText'
    | 'UserManual'
    | 'UseSupplierErpCodeAsKey'
    | 'ValidateExportXml';

export type SettingConfig = Omit<SystemConfigurationDTO, 'Name'> & { Name: CompanySettingName };

export const useCompanySetting = (name: CompanySettingName) => {
    const settingsList = useSelector(selectCurrentCompanySettings);

    const getByName = useCallback(
        (name: CompanySettingName) => {
            return (
                (settingsList.find((setting) => {
                    return setting.Name === name;
                }) as SettingConfig) || null
            );
        },
        [settingsList],
    );
    return getByName(name);
};
