import { debounce } from 'lodash-es';
import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Dispatchable2, Dispatchable3, Dispatchable4 } from 'redux-dispatchers';

import { createDataId } from '../../../../../common/utils/dataId';
import { formatDate } from '../../../../../common/utils/formatters';
import { LoadableData } from '../../../../../common/utils/LoadableData';
import { DEFAULT_CURRENCY_PLACEHOLDER } from '../../../../../common/constants/appConstants';
import { isAuthorized, Role } from '../../../../../common/user/userPermissionUtil';
import { ContentBlockBody } from '../../../../../components/ContentBlock/ContentBlockBody';
import { ContentBlockFooter, ContentBlockFooterType } from '../../../../../components/ContentBlock/ContentBlockFooter';
import Pager from '../../../../../components/Pager/Pager';
import Table from '../../../../../components/Table/Table';
import TableBody from '../../../../../components/Table/TableBody';
import TableCell, { ResponsiveCellType } from '../../../../../components/Table/TableCell';
import TableHead from '../../../../../components/Table/TableHead';
import { DataTableHeader } from '../../../../../components/Table/TableHeader';
import TableRow from '../../../../../components/Table/TableRow';
import { Button, ButtonType } from '../../../../../components/Buttons/Button';
import { ICONS } from '../../../../../components/Icon/Icon';
import EmptySearchResults from '../../../../../components/EmptySearchResults/EmptySearchResults';
import Status from '../../../../../components/Status/Status';
import { TagSelectType } from '../../../../../components/TagSelect/TagSelect';
import { TableFilter } from '../../../../../components/Table/components/filter/TableFilters';
import { getSupplierList } from '../../../../purchase-orders/PurchaseOrdersViewHelper';
import { ContentBlockHeader, ContentBlockHeaderType } from '../../../../../components/ContentBlock/ContentBlockHeader';
import PropertyListItem from '../../../../../components/PropertyListItem/PropertyListItem';
import { Select } from '../../../../../components/Select/Select';
import { InvoicesSearchParams } from './InvoicesListReducers';
import { DateRangeValues } from '../../../../../components/CalendarDateRangeModal/CalendarDateRangeModal';
import { SearchDateBy } from '../../../../invoice-details/components/invoice-slider/InvoiceSliderHelpers';
import {
    DEFAULT_RESTRICTION,
    getInvoiceStatusType,
    getTimeFrameObject,
    parseDateRangeFilter,
    parseFilters,
    parseRestrictions,
    PO_INVOICES_FILTER_TYPES,
    SUPPORTED_DATE_PICKER_SCOPE,
} from './InvoicesListHelper';
import {
    BaseSearch,
    PagedListContainer,
    PurchaseOrderInvoicesSearch,
    PurchaseOrderHeaderInvoicesDTO,
    LinkedCountDTO,
    PurchaseOrderInvoicesFilter,
    PurchaseOrdersDTO,
    PurchaseOrderStatus,
    Restriction,
} from '../../../../../services/types/ApiTypes';

import './InvoicesList.scss';
import { useMonetaryScalesFormat } from '../../../../../components/MonetaryPrecisionScales/hooks/useMonetaryScalesFormat';

const dataId = 'purchase-order-Invoices';

export interface Props {
    purchaseOrderLinkedInvoicesCount: LoadableData<LinkedCountDTO>;
    purchaseOrderInvoicesLoadable: LoadableData<PagedListContainer<PurchaseOrderHeaderInvoicesDTO>, PurchaseOrderInvoicesSearch>;
    searchParams: InvoicesSearchParams;
    purchaseOrder: PurchaseOrdersDTO;
    filterType: number;
}

export interface DispatchProps {
    getInvoicesList: Dispatchable3<BaseSearch, number, PurchaseOrderInvoicesFilter>;
    getLinkedInvoicesCount: Dispatchable4<number, boolean, boolean, boolean>;
    searchPoInvoicesList: Dispatchable3<string, number, boolean>;
    setPoInvoicesPagingOptions: Dispatchable3<number, number, number>;
    sortPoInvoicesList: Dispatchable2<string, number>;
    filterPoInvoices: Dispatchable4<number, Restriction, TableFilter<any>, Restriction[]>;
    linkPurchaseOrderToInvoice: Dispatchable2<number, number>;
    unlinkPurchaseOrderFromInvoice: Dispatchable2<number, number>;
}

export type InvoicesListViewProps = Props & DispatchProps & WithTranslation;

const InvoicesList = (props: InvoicesListViewProps) => {
    const [mounted, setMounted] = React.useState<boolean>(null);
    const [appliedFilters, setAppliedFilters] = React.useState<TableFilter<any>[]>(null);
    const [datePickerActiveScope, setDatePickerActiveScope] = React.useState<string>(SearchDateBy.INVOICE);
    const monetaryScalesFormat = useMonetaryScalesFormat();
    const isLinkingAllowed = () => {
        return (
            (isAuthorized(Role.CanLinkInvoiceToPurchaseOrder) && props.purchaseOrder?.OrderStatus !== PurchaseOrderStatus.Confirmed) ||
            props.purchaseOrder?.OrderStatus === PurchaseOrderStatus.Confirmed
        );
    };

    React.useEffect(() => {
        if (props.purchaseOrder?.Id) {
            props.getLinkedInvoicesCount(props.purchaseOrder.Id, !isLinkingAllowed(), true, false);
            setMounted(true);
        }
    }, []);

    React.useEffect(() => {
        if (mounted && props.purchaseOrder?.Id) {
            props.getLinkedInvoicesCount(props.purchaseOrder.Id, !isLinkingAllowed(), false, true);
        }
    }, [props.purchaseOrder?.Currency]);

    React.useEffect(() => {
        const { searchParams } = props;
        const filters = (searchParams && parseRestrictions(searchParams)) || [];
        if (filters !== appliedFilters) {
            setAppliedFilters(filters);
        }
    }, [props.searchParams]);

    const debounceDoSearch = debounce((searchString: string) => {
        props.searchPoInvoicesList(searchString, props.purchaseOrder.Id, false);
    }, 400);

    const debounceDoFilter = debounce((filter?: TableFilter<any>) => {
        props.filterPoInvoices(props.purchaseOrder.Id, parseFilters(filter), filter, null);
    }, 400);

    const getSearchValue = () => {
        const searchRestriction = props.searchParams.Restrictions.find((r) => r.Field === DEFAULT_RESTRICTION);
        return searchRestriction ? searchRestriction.Value : null;
    };

    const getCurrentDateRange = () => {
        const timeFrame = getTimeFrameObject(datePickerActiveScope || SearchDateBy.INVOICE);
        const dateRangeRestrictionFrom = props.searchParams.Restrictions.find((r) => r.Field === timeFrame.from);
        const dateRangeRestrictionTo = props.searchParams.Restrictions.find((r) => r.Field === timeFrame.to);
        return [dateRangeRestrictionFrom?.Value || null, dateRangeRestrictionTo?.Value || null] as [Date, Date];
    };

    const handlePageChange = (page: number, pageSize?: number) => {
        props.setPoInvoicesPagingOptions(props.purchaseOrder.Id, page, pageSize);
    };

    const handleSortClick = (value: string) => {
        props.sortPoInvoicesList(value, props.purchaseOrder.Id);
    };

    const isFilterActive = (columnName: string): TableFilter<any> => {
        const { searchParams } = props;
        const filters = (searchParams && parseRestrictions(searchParams)) || [];
        return filters.find((filter: TableFilter<any>) => filter.columnName === columnName);
    };

    const getAppliableFilters = () => {
        const filtersAvailable = [
            {
                placeholder: props.t('view.global.filters.any'),
                tagSelectType: TagSelectType.DEFAULT,
                values: (!!isFilterActive('SupplierId') && isFilterActive('SupplierId').values) || [],
                loadItems: getSupplierList,
                columnName: 'SupplierId',
                label: props.t('view.PurchaseOrders.Invoices.Column.Supplier'),
                searchOnFocus: true,
                onSelectChangeCallback: (items: any) => {
                    if (!isFilterActive('SupplierId')) {
                        debounceDoFilter({
                            columnName: 'SupplierId',
                            label: props.t('view.PurchaseOrders.Invoices.Column.Supplier'),
                            loadItems: getSupplierList,
                            values: items.values,
                            tagSelectType: TagSelectType.DEFAULT,
                            onSelectChangeCallback: debounceDoFilter,
                            searchOnFocus: true,
                        });
                    } else {
                        debounceDoFilter({
                            ...isFilterActive('SupplierId'),
                            values: items.values,
                        });
                    }
                },
            },
        ];
        return filtersAvailable;
    };

    const onDateRangeChange = (v: DateRangeValues) => {
        if (v.scope) {
            setDatePickerActiveScope(v.scope);
        }

        props.filterPoInvoices(props.purchaseOrder.Id, null, null, parseDateRangeFilter(v.value, v.scope));
    };

    const handleRemoveFilter = (index: number, filter: TableFilter<any>) => {
        const removedFilter = appliedFilters.find((f) => {
            return f.columnName === filter.columnName;
        });
        // filters with empty values list will be removed upon filtering, hence empty the values
        removedFilter.values = [];
        debounceDoFilter(removedFilter);
    };

    const handleSearchInputClick = (e: React.ChangeEvent<HTMLInputElement>) => {
        debounceDoSearch(e.target.value);
    };

    const resetFilters = () => {
        props.searchPoInvoicesList(undefined, props.purchaseOrder.Id, true);
    };

    const quickFilterMenu = () => {
        return (
            <Select
                dataId={createDataId(dataId, 'LinkedType')}
                onChange={(type) => {
                    if (type) {
                        props.getInvoicesList(undefined, props.purchaseOrder.Id, Number(type.value));
                    }
                }}
                value={{
                    text: props.t(PO_INVOICES_FILTER_TYPES[props.filterType || 0]),
                    value: props.filterType || '0',
                }}
                items={Object.keys(PO_INVOICES_FILTER_TYPES).map((key) => ({
                    text: props.t(PO_INVOICES_FILTER_TYPES[key]),
                    value: key,
                }))}
                placeholder={props.t('view.PurchaseOrders.Type')}
            />
        );
    };

    const toggleLinkPO = (poRow: PurchaseOrderHeaderInvoicesDTO) => {
        const action = poRow.IsLinked ? props.unlinkPurchaseOrderFromInvoice : props.linkPurchaseOrderToInvoice;
        action(poRow.Id, props.purchaseOrder.Id);
    };

    return (
        <div className="po-invoices">
            <div className="po-invoices__counts-row">
                <div className="po-invoices__counts-left">
                    <PropertyListItem
                        dataId={createDataId(dataId)}
                        showZeroValue={true}
                        label={props.t('view.PurchaseOrders.Invoices.Totals.LinkedCount')}
                        value={props.purchaseOrderLinkedInvoicesCount?.payload?.LinkedObjectsCount}
                    />
                    <PropertyListItem
                        dataId={createDataId(dataId)}
                        showZeroValue={true}
                        label={props.t('view.PurchaseOrders.Invoices.Totals.LinkedByMe')}
                        value={props.purchaseOrderLinkedInvoicesCount?.payload?.LinkedByMeObjectsCount}
                    />
                </div>
                <div className="po-invoices__counts-right">
                    <PropertyListItem
                        dataId={createDataId(dataId)}
                        showZeroValue={true}
                        label={props.t('view.PurchaseOrders.Invoices.Totals.LinkedNetTotal')}
                        value={`${monetaryScalesFormat.netVatTotal(props.purchaseOrderLinkedInvoicesCount?.payload?.LinkedObjectsSumWithoutVat)} ${props.purchaseOrderLinkedInvoicesCount?.payload
                            ?.Currency || DEFAULT_CURRENCY_PLACEHOLDER}`}
                    />
                    <PropertyListItem
                        dataId={createDataId(dataId)}
                        showZeroValue={true}
                        label={props.t('view.PurchaseOrders.Invoices.Totals.LinkedGrandTotal')}
                        value={`${monetaryScalesFormat.netVatTotal(props.purchaseOrderLinkedInvoicesCount?.payload?.LinkedObjectsTotal)} ${props.purchaseOrderLinkedInvoicesCount?.payload?.Currency ||
                            DEFAULT_CURRENCY_PLACEHOLDER}`}
                    />
                </div>
            </div>

            {!props.purchaseOrderLinkedInvoicesCount?.payload ||
                (!(!isLinkingAllowed() && props.purchaseOrderLinkedInvoicesCount?.payload?.LinkedObjectsTotal < 1) && (
                    <ContentBlockHeader
                        className="po-invoices"
                        type={ContentBlockHeaderType.COMPACT}
                        dataId={createDataId(dataId, 'Header')}
                        hasSearch={true}
                        hasStaticFilters={true}
                        onDateRangeChange={onDateRangeChange}
                        dateRange={getCurrentDateRange()}
                        datePickerScopeList={SUPPORTED_DATE_PICKER_SCOPE.map((e) => {
                            return { text: props.t(e.text), value: e.value };
                        })}
                        t={props.t}
                        datePickerLabel={props.t(SUPPORTED_DATE_PICKER_SCOPE.find((e) => e.value === datePickerActiveScope)?.text)}
                        datePickerActiveScope={datePickerActiveScope}
                        hasDateFilter={true}
                        searchOptions={{
                            dataId: createDataId(dataId, 'Search'),
                            value: getSearchValue(),
                            onChange: handleSearchInputClick,
                            onClick: debounceDoSearch,
                            withSearchButton: true,
                            withFilterButton: true,
                            quickFilterMenu: quickFilterMenu(),
                            quickFilterLabel: props.t('view.PurchaseOrders.Invoices.Filter.Label.LinkedWithCurrent'),
                        }}
                        filterable={true}
                        filterOptions={{
                            dataId: createDataId(dataId, 'tableFilters'),
                            className: 'filter',
                            t: props.t,
                            appliedFilters: getAppliableFilters(),
                            onRemove: handleRemoveFilter,
                        }}
                    />
                ))}
            {!(!isLinkingAllowed() && props.purchaseOrderLinkedInvoicesCount?.payload?.LinkedObjectsCount < 1) && props.purchaseOrderInvoicesLoadable.payload?.Items?.length > 0 ? (
                <>
                    <div className="po-invoices__table">
                        <ContentBlockBody className="po-invoice__table-content" loading={props.purchaseOrderInvoicesLoadable.loading} dataId="contentBlockBodyPoInvoicesList">
                            <Table>
                                <DataTableHeader data-id={createDataId(dataId, 'Table', 'Header')}>
                                    <TableRow>
                                        <TableHead
                                            sortable={true}
                                            searchParams={props.purchaseOrderInvoicesLoadable.request?.SearchParams}
                                            columnName="Number"
                                            onClick={handleSortClick}
                                            dataId={createDataId(dataId, 'Number')}
                                            style={{ width: '14%' }}
                                        >
                                            {props.t('view.PurchaseOrders.Invoices.Column.InvoiceNumber')}
                                        </TableHead>
                                        <TableHead
                                            sortable={true}
                                            searchParams={props.purchaseOrderInvoicesLoadable.request?.SearchParams}
                                            columnName="InvoiceDate"
                                            onClick={handleSortClick}
                                            dataId={createDataId(dataId, 'InvoiceDate')}
                                            style={{ width: '14%' }}
                                        >
                                            {props.t('view.PurchaseOrders.Invoices.Column.InvoiceDate')}
                                        </TableHead>
                                        <TableHead
                                            sortable={true}
                                            searchParams={props.purchaseOrderInvoicesLoadable.request?.SearchParams}
                                            columnName="SupplierName"
                                            onClick={handleSortClick}
                                            dataId={createDataId(dataId, 'SupplierName')}
                                            style={{ width: '14%' }}
                                        >
                                            {props.t('view.PurchaseOrders.Invoices.Column.Supplier')}
                                        </TableHead>
                                        <TableHead
                                            sortable={true}
                                            searchParams={props.purchaseOrderInvoicesLoadable.request?.SearchParams}
                                            columnName="LinkedDate"
                                            onClick={handleSortClick}
                                            dataId={createDataId(dataId, 'LinkedDate')}
                                            style={{ width: '14%' }}
                                        >
                                            {props.t('view.PurchaseOrders.Invoices.Column.LinkedDateTime')}
                                        </TableHead>
                                        <TableHead
                                            sortable={true}
                                            searchParams={props.purchaseOrderInvoicesLoadable.request?.SearchParams}
                                            columnName="LinkerName"
                                            onClick={handleSortClick}
                                            dataId={createDataId(dataId, 'LinkerName')}
                                            style={{ width: '14%' }}
                                        >
                                            {props.t('view.PurchaseOrders.Invoices.Column.LinkedBy')}
                                        </TableHead>
                                        <TableHead
                                            sortable={true}
                                            searchParams={props.purchaseOrderInvoicesLoadable.request?.SearchParams}
                                            columnName="SumWithoutVat"
                                            onClick={handleSortClick}
                                            dataId={createDataId(dataId, 'SumWithoutVat')}
                                            style={{ width: '14%' }}
                                        >
                                            {props.t('view.PurchaseOrders.Invoices.Column.NetTotal')}
                                        </TableHead>
                                        <TableHead
                                            sortable={true}
                                            searchParams={props.purchaseOrderInvoicesLoadable.request?.SearchParams}
                                            columnName="TotalAmountWithVat"
                                            onClick={handleSortClick}
                                            dataId={createDataId(dataId, 'TotalAmountWithVat')}
                                            style={{ width: '14%' }}
                                        >
                                            {props.t('view.PurchaseOrders.Invoices.Column.GrandTotal')}
                                        </TableHead>
                                    </TableRow>
                                </DataTableHeader>
                                <TableBody>
                                    {props.purchaseOrderInvoicesLoadable?.payload &&
                                        props.purchaseOrderInvoicesLoadable.payload.Items.map((row, id) => (
                                            <TableRow key={id} data-id={createDataId(dataId, 'tableRow', id)}>
                                                <TableCell dataId={createDataId(dataId, `${id}.InvoiceStatus`)} hideOverflow={true} responsiveCellType={ResponsiveCellType.LABEL}>
                                                    <Status type={getInvoiceStatusType(row.Status)}>{row.Number || '\u00A0'}</Status>
                                                </TableCell>
                                                <TableCell dataId={createDataId(dataId, `${id}.InvoiceDate`)} hideOverflow={true} responsiveCellType={ResponsiveCellType.LABEL}>
                                                    {formatDate(row?.InvoiceDate) || '\u00A0'}
                                                </TableCell>
                                                <TableCell dataId={createDataId(dataId, `${id}.SupplierName`)} hideOverflow={true} responsiveCellType={ResponsiveCellType.LABEL}>
                                                    {row?.SupplierName || '\u00A0'}
                                                </TableCell>
                                                <TableCell dataId={createDataId(dataId, `${id}.LinkedDate`)} hideOverflow={true}>
                                                    {formatDate(row?.LinkedDate, 'dd.MM.yyyy HH:mm:ss') || '\u00A0'}
                                                </TableCell>
                                                <TableCell dataId={createDataId(dataId, `${id}.LinkerName`)} hideOverflow={true}>
                                                    {row.LinkerName || '\u00A0'}
                                                </TableCell>
                                                <TableCell dataId={createDataId(dataId, `${id}.SumWithoutVat`)} hideOverflow={true}>
                                                    {row.SumWithoutVat ? `${monetaryScalesFormat.netVatTotal(row.SumWithoutVat)} ${row?.Currency || DEFAULT_CURRENCY_PLACEHOLDER}` : '\u00A0'}
                                                </TableCell>
                                                <TableCell dataId={createDataId(dataId, `${id}.TotalAmountWithVat`)} hideOverflow={true}>
                                                    {row.TotalAmountWithVat
                                                        ? `${monetaryScalesFormat.netVatTotal(row.TotalAmountWithVat)} ${row?.Currency || DEFAULT_CURRENCY_PLACEHOLDER} `
                                                        : '\u00A0'}
                                                </TableCell>
                                                <TableCell dataId={createDataId(dataId, `${id}.link`)}>
                                                    <Button
                                                        onClick={() => {
                                                            toggleLinkPO(row);
                                                        }}
                                                        dataId={createDataId(dataId, 'btn', 'link', row.Id)}
                                                        icon={row.IsLinked ? ICONS.UN_LINK_24 : ICONS.LINK_24}
                                                        className="po-action-button"
                                                        buttonType={ButtonType.ICON_TEXT}
                                                        disabled={!isLinkingAllowed() || !isAuthorized(Role.CanLinkPurchaseOrderToInvoice)}
                                                    />
                                                </TableCell>
                                                <TableCell dataId={createDataId(dataId, `${id}.open`)}>
                                                    <a href={`/app/#/invoiceconfirmation/${row.Id}`} target="_blank" rel="noopener noreferrer">
                                                        <Button
                                                            onClick={() => {}}
                                                            dataId={createDataId(dataId, 'btn', 'open', row.Id)}
                                                            icon={ICONS.GO_TO_24}
                                                            className="po-action-button"
                                                            buttonType={ButtonType.ICON_TEXT}
                                                        />
                                                    </a>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>
                        </ContentBlockBody>
                    </div>
                    <ContentBlockFooter type={ContentBlockFooterType.PAGER}>
                        <Pager pages={props.purchaseOrderInvoicesLoadable.payload} onPageChange={handlePageChange} />
                    </ContentBlockFooter>
                </>
            ) : (
                <EmptySearchResults
                    title={!isLinkingAllowed() ? props.t('view.PurchaseOrders.Invoices.EmptySearchRestricted.Title') : props.t('view.PurchaseOrders.Invoices.EmptySearch.Title')}
                    subTitle={!isLinkingAllowed() ? props.t('view.PurchaseOrders.Invoices.EmptySearchRestricted.SubTitle') : props.t('view.PurchaseOrders.Invoices.EmptySearch.SubTitle')}
                    resetFiltersCb={isLinkingAllowed() ? resetFilters : null}
                />
            )}
        </div>
    );
};

export default withTranslation()(InvoicesList);
