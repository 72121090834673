import classNames from 'classnames';
import * as React from 'react';

import { BaseComponent } from '../BaseComponent';
import { isAWhiteLabel } from '../../common/utils/whitelabelHelper';

import AddUser from './icons/add-user.svg';
import AddUser16 from './icons/add-user_16.svg';
import AddUser24x16 from './icons/add-user_24x16.svg';
import Alert from './icons/alert.svg';
import ArrowAfter from './icons/arrow-after.svg';
import ArrowBefore from './icons/arrow-before.svg';
import ArrowDownSmall from './icons/arrow-down-small.svg';
import ArrowRight from './icons/arrow-right.svg';
import Assistant from './icons/assistant.svg';
import Back from './icons/back.svg';
import Backspace24 from './icons/Backspace-24.svg';
import Calendar from './icons/calendar.svg';
import CheckmarkSmall from './icons/checkmark-small.svg';
import Checkmark from './icons/checkmark.svg';
import CloseBold from './icons/close-bold.svg';
import CloseSmall from './icons/close-small.svg';
import Close from './icons/close.svg';
import Company from './icons/company.svg';
import Delete from './icons/delete.svg';
import DotMenu from './icons/dot-menu.svg';
import Draggable from './icons/draggable.svg';
import Duplicate from './icons/duplicate.svg';
import Edit from './icons/edit.svg';
import Excel from './icons/excel.svg';
import Email from './icons/email.svg';
import Presentation from './icons/presentation.svg';
import Export from './icons/export.svg';
import Flash from './icons/Flash.svg';
import FileBlank from './icons/file-blank.svg';
import Filter from './icons/filter.svg';
import FilterLines from './icons/filter_lines.svg';
import FilterLinesActive from './icons/filter_lines_active.svg';
import FilterSmall from './icons/filter_small.svg';
import Austria from './icons/flag_austria_16.svg';
import AustriaSquared from './icons/flag_austria_sq.svg';
import CzechRepublic from './icons/flag_czech_republic_16.svg';
import CzechRepublicSquared from './icons/flag_czech_republic_sq.svg';
import Estonia from './icons/flag_estonia_16.svg';
import EstoniaSquared from './icons/flag_estonia_sq.svg';
import Latvia from './icons/flag_latvia_16.svg';
import LatviaSquared from './icons/flag_latvia_sq.svg';
import Lithuania from './icons/flag_lithuania_16.svg';
import LithuaniaSquared from './icons/flag_lithuania_sq.svg';
import Hungary from './icons/flag_hungary_16.svg';
import HungarySquared from './icons/flag_hungary_sq.svg';
import Poland from './icons/flag_poland_16.svg';
import PolandSquared from './icons/flag_poland_sq.svg';
import Germany from './icons/flag_germany_16.svg';
import GermanySquared from './icons/flag_germany_sq.svg';
import Croatia from './icons/flag_croatia_16.svg';
import CroatiaSquared from './icons/flag_croatia_sq.svg';
import Cyprus from './icons/flag_cyprus.svg';
import CyprusSquared from './icons/flag_cyprus_sq.svg';
import Russia from './icons/flag_russia_16.svg';
import RussiaSquared from './icons/flag_russia_sq.svg';
import Romania from './icons/flag_romania_16.svg';
import RomaniaSquared from './icons/flag_romania_sq.svg';
import Serbia from './icons/flag_serbia_16.svg';
import SerbiaSquared from './icons/flag_serbia_sq.svg';
import Slovakia from './icons/flag_slovakia_16.svg';
import SlovakiaSquared from './icons/flag_slovakia_sq.svg';
import UnitedKingdom from './icons/flag_uk_16.svg';
import UnitedKingdomSquared from './icons/flag_uk_sq.svg';
import MonacoSquared from './icons/flag_monaco_sq.svg';
import UkraineSquared from './icons/flag_ukraine_sq.svg';
import UnitedArabEmirates from './icons/flag_united_arab_emirates.svg';
import UnitedArabEmiratesSquared from './icons/flag_united_arab_emirates_sq.svg';
import ItalySquared from './icons/flag_italy_sq.svg';
import BelgiumSquared from './icons/flag_belgium_sq.svg';
import IrelandSquared from './icons/flag_ireland_sq.svg';
import DenmarkSquared from './icons/flag_denmark_sq.svg';
import FinlandSquared from './icons/flag_finland_sq.svg';
import SwedenSquared from './icons/flag_sweden_sq.svg';
import NetherlandsSquared from './icons/flag_netherlands_sq.svg';
import FranceSquared from './icons/flag_france_sq.svg';
import BulgariaSquared from './icons/flag_bulgaria_sq.svg';
import LuxembourgSquared from './icons/flag_luxembourg_sq.svg';
import SwitzerlandSquared from './icons/flag_switzerland_sq.svg';
import IcelandSquared from './icons/flag_iceland_sq.svg';
import NorwaySquared from './icons/flag_norway_sq.svg';
import LiechtensteinSquared from './icons/flag_liechtenstein_sq.svg';
import AndorraSquared from './icons/flag_andorra_sq.svg';
import Australia from './icons/flag_australia.svg';
import AustraliaSquared from './icons/flag_australia_sq.svg';
import GreeceSquared from './icons/flag_greece_sq.svg';
import AlbaniaSquared from './icons/flag_albania_sq.svg';
import VaticanSquared from './icons/flag_vatican_sq.svg';
import SloveniaSquared from './icons/flag_slovenia_sq.svg';
import MontenegroSquared from './icons/flag_montenegro_sq.svg';
import SanMarinoSquared from './icons/flag_san_marino_sq.svg';
import NorthMacedoniaSquared from './icons/flag_north_macedonia_sq.svg';
import BosniaAndHerzegovinaSquared from './icons/flag_bosnia_and_herzegovina_sq.svg';
import BelarusSquared from './icons/flag_belarus_sq.svg';
import MaltaSquared from './icons/flag_malta_sq.svg';
import MoldovaSquared from './icons/flag_moldova_sq.svg';
import PortugalSquared from './icons/flag_portugal_sq.svg';
import SpainSquared from './icons/flag_spain_sq.svg';
import ChinaSquared from './icons/flag_china_sq.svg';
import JapanSquared from './icons/flag_japan_sq.svg';
import CanadaSquared from './icons/flag_canada_sq.svg';
import USASquared from './icons/flag_usa_sq.svg';
import Globe from './icons/globe.svg';
import ArrowDown from './icons/icon-arrow-down.svg';
import Image from './icons/image.svg';
import Import from './icons/import.svg';
import Info from './icons/info.svg';
import InfoRound from './icons/info-round.svg';
import Logout from './icons/logout.svg';
import MenuArchive from './icons/menu-archive.svg';
import MenuAutomation from './icons/menu-automation.svg';
import MenuDashboard from './icons/menu-dashboard.svg';
import MenuPurchaseInvoices from './icons/menu-purchase-invoices.svg';
import MenuPurchaseOrders from './icons/menu-purchase-orders.svg';
import MenuReceipts from './icons/menu-receipts.svg';
import MenuSalesInvoices from './icons/menu-sales-invoices.svg';
import MenuSettings from './icons/menu-settings.svg';
import More from './icons/more.svg';
import NotificationError from './icons/notification-error.svg';
import NotificationInfo from './icons/notification-info.svg';
import NotificationMinimalError from './icons/notification-minimal-error.svg';
import NotificationMinimalInfo from './icons/notification-minimal-info.svg';
import NotificationMinimalSuccess from './icons/notification-minimal-success.svg';
import NotificationMinimalWarning from './icons/notification-minimal-warning.svg';
import NotificationSuccess from './icons/notification-success.svg';
import NotificationWarning from './icons/notification-warning.svg';
import NotificationWarning2 from './icons/notification-warning-2.svg';
import OpenExternal from './icons/open-external.svg';
import PaginatorArrow from './icons/paginator-arrow.svg';
import Pdf from './icons/pdf.svg';
import Document from './icons/document.svg';
import Xml from './icons/xml.svg';
import Txt from './icons/txt.svg';
import Archive from './icons/archive.svg';
import Plug from './icons/plug.svg';
import PlusThin from './icons/plus-thin.svg';
import Plus from './icons/plus.svg';
import PurchaseOrders from './icons/purchase-orders.svg';
import Refresh from './icons/refresh.svg';
import SaveSprite from './icons/save-sprite.svg';
import Save from './icons/save.svg';
import Search from './icons/search.svg';
import SortingAsc from './icons/sorting-asc.svg';
import SortingUnset from './icons/sorting-unset.svg';
import SplitSprite from './icons/split-sprite.svg';
import Split from './icons/split.svg';
import Status from './icons/status.svg';
import User from './icons/user.svg';
import Wire from './icons/wire.svg';
import MenuBoUser from './icons/menu-bo-users.svg';
import MenuBoIntegrators from './icons/integrators.svg';
import MenuBoDomains from './icons/domains.svg';
import MenuBoResellers from './icons/menu-bo-resellers.svg';
import MenuBoOrganizations from './icons/menu-bo-organizations.svg';
import MenuBoCompanies from './icons/menu-bo-companies.svg';
import LogoBO from './icons/logo-bo.svg';
import BoIcon from './icons/backoffice_icon.svg';
import BoBlueIcon from './icons/backoffice_icon-blue.svg';
import BackOffice from './icons/backoffice.svg';
import BoResellers from './icons/backoffice_resellers.svg';
import BoUsers from './icons/backoffice_users.svg';
import BoCompanies from './icons/backoffice_companies.svg';
import BoOrganizations from './icons/backoffice_organizations.svg';
import BoKey from './icons/backoffice_key.svg';
import BoGrantAccess from './icons/backoffice_grant-access.svg';
import TopMenuUser from './icons/top-menu-user.svg';
import TopMenuLogout from './icons/top-menu-logout.svg';
import TopMenuCompany from './icons/top-user-company.svg';
import TopMenuLanguage from './icons/top-menu-language.svg';
import LoadingInput from './icons/input-loading.svg';
import SuccessInput from './icons/input-success.svg';
import Confirmed from './icons/confirmed.svg';
import Assigned from './icons/assigned.svg';
import AutoSkip from './icons/auto-skip.svg';
import Skipped from './icons/skipped.svg';
import CurrentConfirm from './icons/current-confirm.svg';
import Reject from './icons/reject.svg';
import UpComingFlow from './icons/up-coming-flow.svg';
import UserUp from './icons/user-up.svg';
import UserDown from './icons/user-down.svg';
import DragFlow from './icons/drag-flow.svg';
import UserAddFlow from './icons/user-add-flow.svg';
import ProfileUser from './icons/profile-user.svg';
import Notifications from './icons/notifications.svg';
import ChangePassword from './icons/change-password.svg';
import Chrome from './icons/chrome.svg';
import InternetExplorer from './icons/internetExplorer.svg';
import MicrosoftEdge from './icons/microsoftEdge.svg';
import MozillaFirefox from './icons/mozillaFirefox.svg';
import Opera from './icons/opera.svg';
import Safari from './icons/safari.svg';
import OtherBrowser from './icons/otherBrowser.svg';
import Android from './icons/android.svg';
import Apple from './icons/apple.svg';
import Seal from './icons/seal.svg';
import Lock from './icons/lock.svg';
import Sessions from './icons/sessions.svg';
import Collaps from './icons/collapse.svg';
import Expand from './icons/expand.svg';
import Csv from './icons/csv.svg';
import Table from './icons/table.svg';
import Link from './icons/link.svg';
import OpenInNew from './icons/open-in-new.svg';
import Download from './icons/download.svg';
import Unlink from './icons/unlink.svg';
import Add24 from './icons/Add-24.svg';
import Admin24 from './icons/Admin-24.svg';
import Archive24 from './icons/Archive-24.svg';
import ArrowDown24 from './icons/Arrow-down-24.svg';
import ArrowLeft24 from './icons/Arrow-left-24.svg';
import ArrowUp24 from './icons/Arrow-up-24.svg';
import ArrowRight24 from './icons/Arrow-right-24.svg';
import Alert24 from './icons/Alert-warning-24.svg';
import Calculator24 from './icons/Calculator-24.svg';
import CancelChanges24 from './icons/Cancel-changes-24.svg';
import Calendar24 from './icons/Calendar-24.svg';
import ChevronDown24 from './icons/Chevron-down-24.svg';
import ChevronLeft from './icons/chevron-left.svg';
import ChevronRight from './icons/chevron-right.svg';
import ChevronUp24 from './icons/Chevron-up-24.svg';
import Close24 from './icons/Close-24.svg';
import CollapseAll24 from './icons/Collapse-all-24.svg';
import Config24 from './icons/Config-24.svg';
import Copy24 from './icons/Copy-24.svg';
import Company24 from './icons/Company-24.svg';
import Combine24 from './icons/Combine-24.svg';
import Comment24 from './icons/Comment-24.svg';
import Check24 from './icons/Check-24.svg';
import Delete24 from './icons/Delete-24.svg';
import Discount24 from './icons/Discount-24.svg';
import DiscountBody24 from './icons/Discount-body-24.svg';
import DiscountArrow24 from './icons/Discount-arrow-24.svg';
import DensityRelaxed24 from './icons/Density-relaxed-24.svg';
import Document24 from './icons/Document-24.svg';
import Download24 from './icons/Download-24.svg';
import Edit24 from './icons/Edit-24.svg';
import Email24 from './icons/Email-24.svg';
import ExpandAll24 from './icons/Expand-all-24.svg';
import Export24 from './icons/Export-24.svg';
import ExtraChargeArrow24 from './icons/Extra-charge-arrow-24.svg';
import Filter24 from './icons/Filter-24.svg';
import FullScreen24 from './icons/Full-screen-24.svg';
import FullWidth24 from './icons/Full-width-24.svg';
import NormalWidth24 from './icons/Normal-width-24.svg';
import Home24 from './icons/Home-24.svg';
import Import24 from './icons/Import-24.svg';
import Info24 from './icons/Info-24.svg';
import Inbox24 from './icons/Inbox-24.svg';
import Invite24 from './icons/Invite-24.svg';
import Kickstart24 from './icons/Kickstart-24.svg';
import Lang24 from './icons/Language-24.svg';
import Lock24 from './icons/Lock-24.svg';
import Logout24 from './icons/Logout-24.svg';
import More24 from './icons/More-24.svg';
import Outbox24 from './icons/Outbox-24.svg';
import PlusThick24 from './icons/plus-thick-24.svg';
import Process24 from './icons/Process-24.svg';
import Rearrange24 from './icons/Rearrange-24.svg';
import RearrangeListView24 from './icons/Rearrange-list-view-24.svg';
import Reset from './icons/reset.svg';
import Save24 from './icons/Save-24.svg';
import Search24 from './icons/Search-24.svg';
import Sort24 from './icons/Sort-24.svg';
import SortDown24 from './icons/Sort-down-24.svg';
import SortUp24 from './icons/Sort-up-24.svg';
import Split24 from './icons/Split-24.svg';
import Substract24 from './icons/Substract-24.svg';
import UPSmall from './icons/up-logo-small.svg';
import Upload24 from './icons/Upload-24.svg';
import User24 from './icons/User-24.svg';
import UnLink24 from './icons/Document-not-linked-24.svg';
import Link24 from './icons/Document-link-24.svg';
import GoTo24 from './icons/Go-to-24.svg';
import Undo24 from './icons/Undo-24.svg';
import View24 from './icons/View-24.svg';
import HorizontalView24 from './icons/Horizontal-view-24.svg';
import Template24 from './icons/Template-24.svg';
import EmptyDocs from './icons/empty_docs.svg';
import NotificationWarningFilled from './icons/notification-warning-filled.svg';
import DragHandlerLong from './icons/drag_handler_long.svg';
import SettingsIcon from './icons/settings-icon-black.svg';
import CircularProgressIconSmall from './icons/circular_progress_sm.svg';
import DragAndDrop from './icons/drag-and-drop.svg';

const style = require('./Icon.scss');
export const ICONS = {
    ADD_USER: AddUser.id,
    ADD_USER_16: AddUser16.id,
    ADD_USER_24_16: AddUser24x16.id,
    ALERT: Alert.id,
    ARROW_AFTER: ArrowAfter.id,
    ARROW_BEFORE: ArrowBefore.id,
    ARROW_DOWN: ArrowDown.id,
    ARROW_DOWN_SMALL: ArrowDownSmall.id,
    ARROW_RIGHT: ArrowRight.id,
    ASSISTANT: Assistant.id,
    BACK: Back.id,
    BACKSPACE_24: Backspace24.id,
    CALENDAR: Calendar.id,
    CHECKMARK: Checkmark.id,
    CHECKMARK_SMALL: CheckmarkSmall.id,
    CLOSE: Close.id,
    CLOSE_BOLD: CloseBold.id,
    CLOSE_SMALL: CloseSmall.id,
    COMPANY: Company.id,
    DELETE: Delete.id,
    DOT_MENU: DotMenu.id,
    DOWNLOAD: Download.id,
    DRAGGABLE: Draggable.id,
    DUPLICATE: Duplicate.id,
    EDIT: Edit.id,
    EMPTY_DOCS: EmptyDocs.id,
    AT: Austria.id,
    AT_SQ: AustriaSquared.id,
    CZ: CzechRepublic.id,
    CZ_SQ: CzechRepublicSquared.id,
    EE: Estonia.id,
    EE_SQ: EstoniaSquared.id,
    HU: Hungary.id,
    HU_SQ: HungarySquared.id,
    EXCEL: Excel.id,
    PRESENTATION: Presentation.id,
    EMAIL: Email.id,
    EXPORT: Export.id,
    FLASH: Flash.id,
    FILE_BLANK: FileBlank.id,
    DOCUMENT: Document.id,
    XML: Xml.id,
    TXT: Txt.id,
    ARCHIVE: Archive.id,
    FILTER: Filter.id,
    FILTER_LINES: FilterLines.id,
    FILTER_LINES_ACTIVE: FilterLinesActive.id,
    GLOBE: Globe.id,
    IMAGE: Image.id,
    IMPORT: Import.id,
    INFO: Info.id,
    INFO_ROUND: InfoRound.id,
    LINK: Link.id,
    LV: Latvia.id,
    LV_SQ: LatviaSquared.id,
    LT: Lithuania.id,
    LT_SQ: LithuaniaSquared.id,
    PL: Poland.id,
    PL_SQ: PolandSquared.id,
    RO: Romania.id,
    RO_SQ: RomaniaSquared.id,
    RU: Russia.id,
    RU_SQ: RussiaSquared.id,
    LOGOUT: Logout.id,
    MenuDashboard: MenuDashboard.id,
    MenuPurchaseInvoices: MenuPurchaseInvoices.id,
    MenuPurchaseOrders: MenuPurchaseOrders.id,
    MenuReceipts: MenuReceipts.id,
    MenuSalesInvoices: MenuSalesInvoices.id,
    MenuArchive: MenuArchive.id,
    MenuAutomation: MenuAutomation.id,
    MenuSettings: MenuSettings.id,
    MORE: More.id,
    OPEN_EXTERNAL: OpenExternal.id,
    OPEN_IN_NEW: OpenInNew.id,
    PAGINATOR_ARROW: PaginatorArrow.id,
    PDF: Pdf.id,
    PLUS: Plus.id,
    PLUS_THIN: PlusThin.id,
    PURCHASE_ORDERS: PurchaseOrders.id,
    REFRESH: Refresh.id,
    SAVE: Save.id,
    SAVE_SPRITE: SaveSprite.id,
    SEARCH: Search.id,
    RS: Serbia.id,
    RS_SQ: SerbiaSquared.id,
    SK: Slovakia.id,
    SK_SQ: SlovakiaSquared.id,
    SORTING_ASC: SortingAsc.id,
    SORTING_UNSET: SortingUnset.id,
    SPLIT: Split.id,
    SPLIT_SPRITE: SplitSprite.id,
    STATUS: Status.id,
    GB: UnitedKingdom.id,
    GB_SQ: UnitedKingdomSquared.id,
    USER: User.id,
    NOTIFICATION_SUCCESS: NotificationSuccess.id,
    NOTIFICATION_INFO: NotificationInfo.id,
    NOTIFICATION_WARNING: NotificationWarning.id,
    NOTIFICATION_WARNING_2: NotificationWarning2.id,
    NOTIFICATION_ERROR: NotificationError.id,
    NOTIFICATION_MINIMAL_SUCCESS: NotificationMinimalSuccess.id,
    NOTIFICATION_MINIMAL_INFO: NotificationMinimalInfo.id,
    NOTIFICATION_MINIMAL_WARNING: NotificationMinimalWarning.id,
    NOTIFICATION_MINIMAL_ERROR: NotificationMinimalError.id,
    PLUG: Plug.id,
    WIRE: Wire.id,
    MENU_BO_USER: MenuBoUser.id,
    MENU_BO_RESELLER: MenuBoResellers.id,
    MENU_BO_INTEGRATORS: MenuBoIntegrators.id,
    MENU_BO_DOMAINS: MenuBoDomains.id,
    MENU_BO_ORGANIZATIONS: MenuBoOrganizations.id,
    MENU_BO_COMPANIES: MenuBoCompanies.id,
    LOGO_BO: LogoBO.id,
    BO_ICON: isAWhiteLabel ? BoIcon.id : BoBlueIcon.id,
    TOP_MENU_USER: TopMenuUser.id,
    TOP_MENU_LOGOUT: TopMenuLogout.id,
    TOP_MENU_COMPANY: TopMenuCompany.id,
    TOP_MENU_LANGUAGE: TopMenuLanguage.id,
    GER: Germany.id,
    GER_SQ: GermanySquared.id,
    CR: Croatia.id,
    HR_SQ: CroatiaSquared.id,
    CY: Cyprus.id,
    CY_SQ: CyprusSquared.id,
    LoadingInput: LoadingInput.id,
    SuccessInput: SuccessInput.id,
    BACK_OFFICE: BackOffice.id,
    BACK_OFFICE_COMPANIES: BoCompanies.id,
    BACK_OFFICE_GRANT_ACCESS: BoGrantAccess.id,
    BACK_OFFICE_KEY: BoKey.id,
    BACK_OFFICE_ORGANIZATIONS: BoOrganizations.id,
    BACK_OFFICE_RESELLERS: BoResellers.id,
    BACK_OFFICE_USERS: BoUsers.id,
    CONFIRMED: Confirmed.id,
    ASSIGNED: Assigned.id,
    AUTO_SKIP: AutoSkip.id,
    SKIPPED: Skipped.id,
    CURRENT_CONFIRM: CurrentConfirm.id,
    REJECT: Reject.id,
    UP_COMING_FLOW: UpComingFlow.id,
    USER_UP: UserUp.id,
    USER_DOWN: UserDown.id,
    DRAG_FLOW: DragFlow.id,
    USER_ADD_FLOW: UserAddFlow.id,
    PROFILE_USER: ProfileUser.id,
    NOTIFICATIONS: Notifications.id,
    CHANGE_PASSWORD: ChangePassword.id,
    CHROME: Chrome.id,
    INTERNET_EXPLORER: InternetExplorer.id,
    MICROSOFT_EDGE: MicrosoftEdge.id,
    MOZILA_FIREFOX: MozillaFirefox.id,
    OPERA: Opera.id,
    SAFARI: Safari.id,
    OTHER_BROWSER: OtherBrowser.id,
    ANDROID: Android.id,
    APPLE: Apple.id,
    SEAL: Seal.id,
    LOCK: Lock.id,
    SESSIONS: Sessions.id,
    COLLAPS: Collaps.id,
    EXPAND: Expand.id,
    CSV: Csv.id,
    TABLE: Table.id,
    MC_SQ: MonacoSquared.id,
    UA_SQ: UkraineSquared.id,
    AE: UnitedArabEmirates.id,
    AE_SQ: UnitedArabEmiratesSquared.id,
    IT_SQ: ItalySquared.id,
    BE_SQ: BelgiumSquared.id,
    IE_SQ: IrelandSquared.id,
    DK_SQ: DenmarkSquared.id,
    FI_SQ: FinlandSquared.id,
    SE_SQ: SwedenSquared.id,
    NL_SQ: NetherlandsSquared.id,
    FR_SQ: FranceSquared.id,
    BG_SQ: BulgariaSquared.id,
    LU_SQ: LuxembourgSquared.id,
    CH_SQ: SwitzerlandSquared.id,
    IS_SQ: IcelandSquared.id,
    NO_SQ: NorwaySquared.id,
    LI_SQ: LiechtensteinSquared.id,
    AD_SQ: AndorraSquared.id,
    AU: Australia.id,
    AU_SQ: AustraliaSquared.id,
    GR_SQ: GreeceSquared.id,
    AL_SQ: AlbaniaSquared.id,
    VA_SQ: VaticanSquared.id,
    SI_SQ: SloveniaSquared.id,
    ME_SQ: MontenegroSquared.id,
    SM_SQ: SanMarinoSquared.id,
    MK_SQ: NorthMacedoniaSquared.id,
    BA_SQ: BosniaAndHerzegovinaSquared.id,
    BY_SQ: BelarusSquared.id,
    MT_SQ: MaltaSquared.id,
    MD_SQ: MoldovaSquared.id,
    ES_SQ: SpainSquared.id,
    PT_SQ: PortugalSquared.id,
    CN_SQ: ChinaSquared.id,
    JP_SQ: JapanSquared.id,
    CA_SQ: CanadaSquared.id,
    US_SQ: USASquared.id,
    UNLINK: Unlink.id,
    ADD_24: Add24.id,
    ADMIN_24: Admin24.id,
    ARCHIVE_24: Archive24.id,
    ARROW_DOWN_24: ArrowDown24.id,
    ARROW_LEFT_24: ArrowLeft24.id,
    ARROW_UP_24: ArrowUp24.id,
    ARROW_RIGHT_24: ArrowRight24.id,
    ALERT_24: Alert24.id,
    CANCEL_CHANGES_24: CancelChanges24.id,
    CALCULATOR_24: Calculator24.id,
    CALENDAR_24: Calendar24.id,
    CHEVRON_DOWN_24: ChevronDown24.id,
    CHEVRON_RIGHT: ChevronRight.id,
    CHEVRON_LEFT: ChevronLeft.id,
    CHEVRON_UP_24: ChevronUp24.id,
    CLOSE_24: Close24.id,
    COLLAPSE_ALL_24: CollapseAll24.id,
    CONFIG_24: Config24.id,
    COPY_24: Copy24.id,
    COMBINE_24: Combine24.id,
    COMPANY_24: Company24.id,
    COMMENT_24: Comment24.id,
    CHECK_24: Check24.id,
    DELETE_24: Delete24.id,
    DISCOUNT_24: Discount24.id,
    DISCOUNT_BODY_24: DiscountBody24.id,
    DISCOUNT_ARROW_24: DiscountArrow24.id,
    DENSITY_RELAXED_24: DensityRelaxed24.id,
    DOCUMENT_24: Document24.id,
    DOWNLOAD_24: Download24.id,
    EDIT_24: Edit24.id,
    EMAIL_24: Email24.id,
    EXPAND_ALL_24: ExpandAll24.id,
    EXPORT_24: Export24.id,
    EXTRA_CHARGE_ARROW_24: ExtraChargeArrow24.id,
    FILTER_24: Filter24.id,
    FILTER_SMALL: FilterSmall.id,
    FULLSCREEN_24: FullScreen24.id,
    FULL_WIDTH_24: FullWidth24.id,
    NORMAL_WIDTH_24: NormalWidth24.id,
    HOME_24: Home24.id,
    IMPORT_24: Import24.id,
    INFO_24: Info24.id,
    INBOX_24: Inbox24.id,
    INVITE_24: Invite24.id,
    KICKSTART_24: Kickstart24.id,
    LANG_24: Lang24.id,
    LOCK_24: Lock24.id,
    LOGOUT_24: Logout24.id,
    MORE_24: More24.id,
    OUTBOX_24: Outbox24.id,
    PLUS_THICK_24: PlusThick24.id,
    PROCESS_24: Process24.id,
    REARRANGE_24: Rearrange24.id,
    REARRANGE_LIST_VIEW_24: RearrangeListView24.id,
    RESET: Reset.id,
    SAVE_24: Save24.id,
    SEARCH_24: Search24.id,
    SORT_24: Sort24.id,
    SORT_DOWN_24: SortDown24.id,
    SORT_UP_24: SortUp24.id,
    SPLIT_24: Split24.id,
    SUBSTRACT_24: Substract24.id,
    UP_LOGO_SMALL: UPSmall.id,
    UPLOAD_24: Upload24.id,
    USER_24: User24.id,
    LINK_24: Link24.id,
    UN_LINK_24: UnLink24.id,
    GO_TO_24: GoTo24.id,
    UNDO_24: Undo24.id,
    VIEW_24: View24.id,
    HORIZONTAL_VIEW_24: HorizontalView24.id,
    TEMPLATE_24: Template24.id,
    NOTIFICATION_WARNING_FILLED: NotificationWarningFilled.id,
    DRAG_HANDLER_LONG: DragHandlerLong.id,
    SETTINGS: SettingsIcon.id,
    CIRCULAR_PROGRESS_SM: CircularProgressIconSmall.id,
    DRAG_AND_DROP: DragAndDrop.id,
};

export enum IconType {
    WIDE = 'WIDE',
    NORMAL = 'NORMAL',
}

export enum IconSize {
    XS = 'XS',
    SM = 'SM',
    MD = 'MD',
    LG = 'LG',
}

export interface IconProps {
    /**
     * Use ICONS constant in Icon.tsx
     */
    name?: any;
    iconName?: any;
    /**
     * Custom class
     */
    className?: string;
    size?: IconSize;
    rotation?: number;
    type?: IconType;
    color?: string;

    onClick?: (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
}

class Icon extends BaseComponent<IconProps> {
    public render() {
        const { size, rotation, type, onClick, color } = this.props;
        const classes = classNames(
            style.icon,
            this.props.className,
            {
                [style['icon--xs']]: size === IconSize.XS,
                [style['icon--sm']]: size === IconSize.SM,
                [style['icon--md']]: size === IconSize.MD,
                [style['icon--lg']]: size === IconSize.LG,
            },
            rotation ? [style[`icon--rotate-${rotation}`]] : '',
            {
                [style['icon--normal']]: type === IconType.NORMAL,
                [style['icon--wide']]: type === IconType.WIDE,
            },
        );
        return (
            <svg
                className={classes}
                focusable="false"
                onClick={onClick}
                style={{
                    color,
                }}
            >
                <use xlinkHref={`#${this.props.name || this.props.iconName}`} />
            </svg>
        );
    }
}

export default Icon;
