import classNames from 'classnames';
import React, { cloneElement, ReactElement, ReactNode, useEffect, useState } from 'react';
import { DragDropContext, Draggable, DraggableProvided, DraggableStateSnapshot, Droppable, DroppableProvided, DropResult } from 'react-beautiful-dnd';
import { useTabletOrMobile } from '../../common/hooks/useTabletOrMobile';
import { addDataId } from '../../common/utils/dataId';
import { Button, ButtonType } from '../Buttons/Button';
import { ICONS } from '../Icon/Icon';
import LabelWrapper from '../LabelWrapper/LabelWrapper';

import './Table.scss';
import Link from '../Link/Link';
import TableBody from './TableBody';
import TableCell, { ResponsiveCellType, TableCellProps } from './TableCell';
import TableHead from './TableHead';
import { DataTableHeader } from './TableHeader';
import TableRow, { TableRowProps } from './TableRow';

export interface TableProps extends React.TableHTMLAttributes<HTMLTableElement> {
    children?: React.ReactNode;
    className?: string;
    draggableRows?: boolean;
    onRowDragEnd?: (result: DropResult) => void;
    fixed?: boolean;
    forwardRef?: React.RefObject<HTMLTableElement>;
}

export const IsDraggingContext = React.createContext<boolean>(false);

const Table: React.StatelessComponent<TableProps> = (props: TableProps) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { className, children, draggableRows, onRowDragEnd, fixed, forwardRef, ...rest } = props;
    const [isDragging, setIsDragging] = useState(false);
    const classes = classNames('data-table', className, { 'data-table--is-dragging': isDragging }, { 'data-table--is-fixed': fixed });

    const [headings, setHeadings] = useState([]);

    const isTabletOrMobile = useTabletOrMobile();

    const [rowStatuses, setRowStatuses] = useState<{ [key: string]: boolean }>({});

    useEffect(() => {
        setRowStatuses({});
        React.Children.forEach(children as React.ReactElement<any>, (e) => {
            if (e.type === DataTableHeader) {
                React.Children.forEach(e.props.children, (row: React.ReactElement<TableRowProps>) => {
                    const tempRow = row.type === TableRow ? row : (row.props.children as React.ReactElement<TableRowProps>); // in some cases row is wrapped with some Provider
                    if (tempRow.type === TableRow) {
                        const headingTexts = React.Children.map<React.ReactElement<unknown>, React.ReactElement<any>>(tempRow.props.children as any, (th) => {
                            if (th?.type === TableHead) {
                                const thChildren = React.Children.toArray(th.props.children);
                                if (thChildren && thChildren[0]) {
                                    return thChildren[0];
                                }
                            }
                        });
                        setHeadings(headingTexts);
                    }
                });
            }
        });
    }, [children]);

    function handleRowClick(index: number) {
        const newRowStatuses = { ...rowStatuses };
        newRowStatuses[index] = !newRowStatuses[index];
        setRowStatuses(newRowStatuses);
    }

    function onDragEnd(result: DropResult) {
        setIsDragging(false);
        props.onRowDragEnd(result);
    }

    function onBeforeDragStart() {
        setIsDragging(true);
    }

    function hasAccessibleTableRows() {
        // validate the table and table rows structure to opt out from responsive table rendering when we wrap TableRow's into Fromik
        // I think we can not access the TableCells inside the Field's render functions
        const tbody = React.Children.toArray(children as any).find((e) => e.type === TableBody);
        if (tbody) {
            const rows = React.Children.toArray(tbody.props.children);
            if (rows.length && rows[0].type === TableRow) {
                return true;
            }
        }
        return false;
    }

    function renderTbody() {
        if (isTabletOrMobile && hasAccessibleTableRows()) {
            return renderResponsiveTableContent(children);
        }

        if (!draggableRows) {
            return children;
        }

        return (
            <IsDraggingContext.Provider value={isDragging}>
                <DragDropContext onBeforeDragStart={onBeforeDragStart} onDragEnd={onDragEnd}>
                    {React.Children.map<any, any>(children, (e) => {
                        if (e.type === TableBody) {
                            return (
                                <Droppable droppableId="table">
                                    {(droppableProvided: DroppableProvided) =>
                                        cloneElement(
                                            e as ReactElement,
                                            {
                                                innerRef: (ref: HTMLElement) => {
                                                    droppableProvided.innerRef(ref);
                                                },
                                                ...droppableProvided.droppableProps,
                                            },
                                            renderTrs(e.props.children, droppableProvided),
                                        )
                                    }
                                </Droppable>
                            );
                        }
                        return e;
                    })}
                </DragDropContext>
            </IsDraggingContext.Provider>
        );
    }

    function renderResponsiveTableContent(children: ReactNode) {
        let rows: ReactElement[] = [];
        React.Children.forEach(children as React.ReactElement<any>, (c) => {
            if (c.type === TableBody) {
                rows = createResponsiveRows(c.props.children);
            }
        });
        return <TableBody>{rows}</TableBody>;
    }

    function renderLinkOrContent(props: TableCellProps, className = 'row__link') {
        const { link, linkTo, dataId, href } = props;
        const onClickProps = props.onClick ? { onClick: props.onClick } : undefined;
        return link ? (
            <Link key={dataId as string} href={href} target={href ? '_blank' : ''} to={linkTo} data-id={addDataId(dataId, '.link').toString()} className={className}>
                {props.children}
            </Link>
        ) : onClickProps ? (
            <span key={dataId as string} {...onClickProps}>
                {props.children}
            </span>
        ) : (
            props.children
        );
    }

    function createResponsiveRows(tableRows: React.ReactElement<any>): any {
        const rows: React.ReactElement<any>[] = [];
        React.Children.forEach(tableRows, (row, index) => {
            if (row?.type === TableRow) {
                const toggleContentRows: any[] = [];
                const label: any[] = [];
                let actions: any = null;
                React.Children.forEach(row.props.children, (e) => {
                    if (e.props?.responsiveCellType === ResponsiveCellType.LABEL) {
                        label.push(renderLinkOrContent(e.props));
                        label.push(' ');
                    }
                });
                React.Children.forEach(row.props.children, (e, i) => {
                    // if no responsiveCellType is set, use first Cell as label
                    if (!label.length && i === 0) {
                        label.push(renderLinkOrContent(e.props));
                    } else if (e.props?.responsiveCellType === ResponsiveCellType.ACTIONS) {
                        actions = <span className="row__actions">{e.props.children}</span>;
                    } else if (e.props.children && e.type === TableCell && e.props?.responsiveCellType !== ResponsiveCellType.LABEL) {
                        if (e.props.children.toString().trim().length > 0) {
                            toggleContentRows.push(
                                <LabelWrapper key={i} label={headings[i]}>
                                    {e.props.href ? renderLinkOrContent(e.props, 'row__toggle-content-link') : e.props.children}
                                </LabelWrapper>,
                            );
                        }
                    }
                });

                const toggleContent = React.createElement(
                    'span',
                    {
                        className: 'row__toggle-content',
                    },
                    toggleContentRows,
                );

                const newRow = React.cloneElement(
                    row,
                    {
                        className: classNames('row', row.props.className, { 'is-content-visible': rowStatuses[index] }),
                    },
                    <td className="row__wrapper">
                        <div className="row__main-content">
                            <Button
                                buttonType={ButtonType.ICON}
                                icon={ICONS.ARROW_DOWN}
                                className="row__toggle"
                                onClick={() => {
                                    handleRowClick(index);
                                }}
                            />
                            <span className="row__label">{label}</span>
                            {actions}
                        </div>
                        {toggleContent}
                    </td>,
                );
                rows.push(newRow);
            }
        });
        return rows;
    }

    function renderTrs(trs: React.ReactElement<any>, droppableProvided: DroppableProvided) {
        return (
            <>
                {React.Children.map<ReactElement<any, any>, any>(trs, (e, i) => {
                    if (e && e.type === TableRow && e.props.draggable) {
                        return (
                            <Draggable draggableId={e.props.id} index={i} key={e.props.id}>
                                {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => cloneElement(e as ReactElement, { provided, snapshot })}
                            </Draggable>
                        );
                    }
                    return e;
                })}
                {droppableProvided.placeholder}
            </>
        );
    }

    return (
        <table ref={forwardRef} className={classes} {...rest}>
            {renderTbody()}
        </table>
    );
};

export default Table;
