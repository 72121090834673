import { numericFormatter } from 'react-number-format';
import { useCompanySetting } from '../../../common/hooks/useCompanySettings';
import { NumericFormatProps } from 'react-number-format/types/types';
import { useCallback } from 'react';
import { isNumericValue, numberToNumericString } from '../../../common/utils/numericUtils';

export type NumericFormatPropsWithNativeRound = NumericFormatProps & {
    useNativeRound?: boolean;
};

export type UseMonetaryScalesFormat = {
    netVatTotal: (value: number | string, props?: NumericFormatPropsWithNativeRound) => string;
};

export const formatNetVatTotal = (value: string | number, props?: NumericFormatPropsWithNativeRound) => {
    const { decimalScale } = props;
    if (!isNumericValue(value)) {
        return value?.toString();
    }
    const decimalCount = props?.decimalScale || Number(decimalScale);
    const numStr = numberToNumericString(value);
    const normalizedValue = !props.useNativeRound ? numStr : Number(numStr).toFixed(decimalCount);
    return numericFormatter(normalizedValue, { decimalScale: decimalCount, ...props });
};

export const useMonetaryScalesFormat = (): UseMonetaryScalesFormat => {
    const monetaryNetVatTotalSetting = useCompanySetting('MonetaryNetVatTotalPrecision');
    const decimalScale = monetaryNetVatTotalSetting?.Value || '2';

    const netVatTotal: UseMonetaryScalesFormat['netVatTotal'] = useCallback(
        (value, props) => {
            return formatNetVatTotal(value, { decimalScale: Number(decimalScale), ...props });
        },
        [decimalScale],
    );

    return { netVatTotal };
};
