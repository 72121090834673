import { ICONS } from '../../../../../../components/Icon/Icon';

export const mainClassName = 'attachment';

export const getIcon = (name: string): string => {
    const extension = name.split('.').pop();
    if (['xls', 'xlr', 'xlsx', 'xlsb', 'csv', 'ods', 'fods', 'odf'].includes(extension)) {
        return ICONS.EXCEL;
    }
    if (['pdf'].includes(extension)) {
        return ICONS.PDF;
    }
    if (['xml'].includes(extension)) {
        return ICONS.XML;
    }
    if (['jpg', 'png', 'jpeg', 'tiff', 'bmp', 'gif', 'svg'].includes(extension)) {
        return ICONS.IMAGE;
    }
    if (['doc', 'docs', 'docx', 'odt', 'fodt', 'rtf', 'pages'].includes(extension)) {
        return ICONS.DOCUMENT;
    }
    if (['txt', 'tex', 'log', 'dat'].includes(extension)) {
        return ICONS.TXT;
    }
    if (['zip', 'rar', '7z'].includes(extension)) {
        return ICONS.ARCHIVE;
    }
    if (['msg', 'eml'].includes(extension)) {
        return ICONS.EMAIL;
    }
    if (['pps', 'ppt', 'pptx'].includes(extension)) {
        return ICONS.PRESENTATION;
    }
    return ICONS.FILE_BLANK;
};

export const downloadBuffer = (arrayBuffer: ArrayBuffer, fileName: string) => {
    const a = document.createElement('a');
    a.href = URL.createObjectURL(new Blob([arrayBuffer], { type: 'application/octet-stream' }));
    a.download = fileName;
    a.click();
};
