import React from 'react';
import { useTranslation } from 'react-i18next';

import { ContentBlockHeader } from '../../../../components/ContentBlock/ContentBlockHeader';
import ContentBlockHeading from '../../../../components/ContentBlock/ContentBlockHeading';
import { createDataId } from '../../../../common/utils/dataId';
import Attachment from './components/Attachment/Attachment';
import { FileType } from './components/FileInput/FileInputTypes';
import FileInput from './components/FileInput/FileInput';
import { AttachmentsProps } from './AttachmentsTypes';

import './Attachments.scss';

const Attachments: React.FC<AttachmentsProps> = ({ files, dataId, dropZoneId, setPurchaseOrderFileValues }) => {
    const { t } = useTranslation();

    const totalFileSize = files?.reduce((prev, curr) => prev + curr.fileSize, 0);

    const handleFileChange = (file: FileType) => {
        setPurchaseOrderFileValues((files) => [...files, file]);
    };

    const handleDelete = (key: string) => {
        setPurchaseOrderFileValues((files) => files.filter((e) => e.key !== key));
    };

    return (
        <div className="attachments">
            <ContentBlockHeader>
                <ContentBlockHeading dataId={createDataId(dataId, 'title')}>{t('component.attachments.title')}</ContentBlockHeading>
            </ContentBlockHeader>
            {files?.map((e) => (
                <Attachment dataId={'purchase-order-attachment'} file={e} key={e.key} onDelete={handleDelete} />
            ))}
            <FileInput files={files} dropZoneId={dropZoneId} totalFileSize={totalFileSize} title={t('component.additionalInfo.purchaseOrder')} dataId="po-upload" onChange={handleFileChange} />
        </div>
    );
};

export default Attachments;
